import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { useManageTableWithSearchParams } from "../../../../helpers/useManageTable";
import { useGetInvoice, useGetInvoiceSearch } from "../../../../services/invoice.service";
import { INVOICE_STATUS, InvoiceDto, PRODUCT_TYPE } from "../../../../models/invoice.dto";
import { InvoicesColumns, getInvoiceStatusColor } from "../data";
import { Avatar,Popover } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { LuEye } from "react-icons/lu";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { moneyFormat } from "../../../../helpers/appHelpers";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import CancelInvoiceModal from "../modals/CancelInvoiceModal";
import TableFilter from "../../../../components/table/filter/TableFilter";
import APISearchInput from "../../../../components/search/APISearchInput";

const InvoicesView = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openInvoiceDetailsModal, setOpenInvoiceDetailsModal] = useState(false);
	const [openCancelInvoiceModal, setOpenCancelInvoiceModal] = useState(false);
	const { formDataValues, setFormData } = useEditFormStore();

	const { mutate: searchInvoices } = useGetInvoiceSearch();
	const {
        tableAPIRef,
        fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<InvoiceDto>({
        fetchHook: useGetInvoice 
	});

	const handleClose = () => setAnchorEl(null);

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];

	const columnsWithAdditions = InvoicesColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

    return (
        <>
            <div className="flex justify-between mb-6">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchInvoices}
                    />
                    <TableFilter
                        fields={[
                            {
                                title: "",
                                fieldName: "",
                                fieldType: "SORT",
                                options: [
                                    {
                                        title: "Date Created",
                                        fieldName: "createdAt",
                                    },
                                ]
                            },
                            {
                                title: "Reference Code",
                                fieldName: "referenceCode",
                                fieldType: "INPUT_FIELD",
                            },
                            {
                                title: "Status",
                                fieldName: "status",
                                fieldType: "CHECKBOX_LIST",
                                options: INVOICE_STATUS,
                            },
                            {
                                title: "Product Type",
                                fieldName: "productType",
                                fieldType: "CHECKBOX_LIST",
                                options: PRODUCT_TYPE,
                            },
                        ]}
                    />
				</div>
            </div>
            <CustomStyledTable
                rows={tableData}
                columns={columnsWithAdditions}
                apiRef={tableAPIRef}
                loading={isLoading}
                pageCount={pageCount}
				setAnchorEl={setAnchorEl}
            />
            <Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        width: "auto",
                        borderRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                    },
                }}
            >
                <div className="flex flex-col justify-center p-3 text-secondary-darkgray">
                    <div
                        onClick={() => {
                            setOpenInvoiceDetailsModal(true);
                            handleClose();
                        }}
                        className="flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg"
                    >
                        <LuEye />
                        <p className="text-2sm">View Details</p>
                    </div>
					{(formDataValues?.status !== "CANCELLED" && Number(formDataValues?.amount)) ? (
						<div
							onClick={() => {
								setOpenCancelInvoiceModal(true);
								handleClose();
							}}
							className="flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg"
						>
							<IoClose className="scale-[1.2]" />
							<p className="text-2sm">Cancel</p>
						</div>
					): null}
                </div>
            </Popover>

			{openInvoiceDetailsModal && (
                <ModalComponent
                    open={openInvoiceDetailsModal}
                    title={formDataValues?.referenceCode}
                    width={550}
                    handleClose={() => setOpenInvoiceDetailsModal(false)}
                >
                    <div className="text-charcoal py-3">
                        <Avatar
                            src={formDataValues?.organization.logoUrl}
                            alt="Logo"
                            classes="rounded-full"
                            sx={{ width: 57, height: 57 }}
                        />
                        <section className="flex items-start justify-between mt-6">
                            <div>
                                <h2 className="text-secondary-lightgray text-2xs">Account</h2>
                                <p className="text-2sm">
                                    {formDataValues?.organization?.name || ""}
                                </p>
                            </div>
                            <div
                                className="rounded-lg px-3 py-2 inline-flex text-white"
                                style={{ background: getInvoiceStatusColor(formDataValues?.status) }}
                            >
                                <p className="text-xs">{formDataValues?.status}</p>
                            </div>
                        </section>
                        <section className="flex items-start justify-between mt-6">
                            <div>
                                <h2 className="text-secondary-lightgray text-2xs">Date Submitted</h2>
                                <p className="text-2sm">
                                    {new Date(formDataValues?.createdAt).toLocaleDateString("en-US", { 
                                        month: "short", day: "numeric", year: "numeric" 
                                    })}
                                </p>
                            </div>
                            <div className="flex flex-col items-end">
                                <h2 className="text-secondary-lightgray text-2xs">Amount</h2>
                                <p className="text-2sm">
                                    {/* <span> {(formDataValues?.currency?.currency?.code || "") + " "}</span> */}
                                    <span className="max-w-[80%] truncate">
                                        {moneyFormat(formDataValues?.amount)} 
                                    </span>
                                </p>
                            </div>
                        </section>
                        {/* <div className="w-full flex justify-end mt-8">
                            <OrangeButton 
                                title="Done"
                                className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
                                onClick={() => setOpenInvoiceDetailsModal(false)}
                            />
                        </div> */}
                    </div>
                </ModalComponent>
			)}
			{openCancelInvoiceModal && (
                <CancelInvoiceModal 
                    open={openCancelInvoiceModal}
                    setOpen={setOpenCancelInvoiceModal}
                    tableAPIRef={tableAPIRef}
                />
			)}
        </>
    );
}
 
export default InvoicesView;