import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, formatDate, enumToStringConverter } from "../../../helpers/appHelpers";
import { LEAVE_REQUEST_OPTIONS } from "../../../models/leave-request.dto";
import { Avatar } from "@mui/material";

export const LeaveRequestsColumns: GridColDef[] = [
	{
		field: "startDate",
		headerName: "Start Date",
		width: 200,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const startDate = formatDate(params);
			return startDate;
		},
	},
	{
		field: "returnDate",
		headerName: "Return Date",
		width: 200,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const returnDate = formatDate(params);
			return returnDate;
		},
	},
	{
		field: "status",
		headerName: "Status",
		width: 220,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getLeaveRequestStatusColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getLeaveRequestStatusColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p>{enumToStringConverter(params.value)}</p>
				</div>
			);
		},
	},
	{
		field: "comment",
		headerName: "Comment",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "leavePackage",
		headerName: "Leave Type",
		headerClassName: "super-app-theme--header",
		width: 220,
		// valueGetter: (params: GridValueGetterParams) => {
		// 	const leaveType = params.row.leavePackage?.leaveType;
		// 	return leaveType?.name;
		// },
		renderCell(params) {
			const leaveType = params.row.leavePackage?.leaveType;

			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: leaveType?.colorCode }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: leaveType?.colorCode, 
							opacity: "15%"
						}}
					/>
					<p>{leaveType?.name}</p>
				</div>
			);
		},
	},
	
	...createdAtmodifiedAtActions
];

export const SuperviseesLeaveRequestColumns: GridColDef[] = [
	LeaveRequestsColumns[0],

	{
		field: "employee",
		headerName: "Employee",
		width: 250,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-4">
					<Avatar
						src={params.value?.photoUrl}
						alt="Dp"
						className="w-10 h-10 rounded-full"
					/>
					<p>{(params.value?.firstName || "") + " " + (params.value?.lastName || "")}</p>
				</div>
			);
		},
	},

	...LeaveRequestsColumns.slice(1)
]

export const LeavePlansColumns: GridColDef[] = [
	{
		field: "intendedStartDate",
		headerName: "Intended Start Date",
		width: 230,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const startDate = formatDate(params);
			return startDate;
		},
	},
	{
		field: "intendedReturnDate",
		headerName: "Intended Return Date",
		width: 230,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const startDate = formatDate(params);
			return startDate;
		},
	},
	{
		field: "comment",
		headerName: "Comment",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "leavePackage",
		headerName: "Leave Type",
		headerClassName: "super-app-theme--header",
		width: 220,
		renderCell(params) {
			const leaveType = params.row.leavePackage?.leaveType;

			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: leaveType?.colorCode }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: leaveType?.colorCode, 
							opacity: "15%"
						}}
					/>
					<p>{leaveType?.name}</p>
				</div>
			);
		},
	},
	
	...createdAtmodifiedAtActions
];

export const getLeaveRequestStatusColor = (status: LEAVE_REQUEST_OPTIONS) => {
	switch(status) {
		case "APPROVED":
			return "#4CAF50"
		case "CANCELLED":
			return "#808080"
		case "DECLINED":
			return "#F44336"
		case "PENDING":
			return "#FFC107"
		default: 
			return "#2196F3"
	}
};