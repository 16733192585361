import { useQuery } from "react-query";
import API from './utils/api';
import { EmployeeQualificationHistoryPaginator, QueryEmployeeQualificationHistoryDto } from "../models/employee-qualification-history.dto";

import ENDPOINTS from "./utils/endpoints";
import {QueryOptionsDto} from "../models/generic.dto";
import { EmployeeJobTitleHistoryPaginator, QueryEmployeeJobTitleHistoryDto } from "../models/employee-job-title-history.dto";
import { EmployeeNotchHistoryPaginator, QueryEmployeeNotchHistoryDto } from "../models/employee-notch-history.dto";
import { EmployeeBandHistoryPaginator, QueryEmployeeBandHistoryDto } from "../models/employee-band-history.dto";
import { EmployeeBasicSalaryHistoryPaginator, QueryEmployeeBasicSalaryHistoryDto } from "../models/employee-basic-pay-history.dto";
import { EmployeeEngagementDto, EmployeeEngagementPaginator, QueryEmployeeEngagementDto } from "../models/employee-engagement.dto";
import { EmployeeDisengagementPaginator, QueryEmployeeDisengagementDto } from "../models/employee-disengagement.dto";

export function useGetEmployeeQualificationHistory(options?: QueryEmployeeQualificationHistoryDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_QUALIFICATIONS;
    const request = (options: QueryEmployeeQualificationHistoryDto) => API.ProcessingCompanyAPI.getEmployeeQualificationHistory(options);

    const { data, isLoading, error, refetch } = useQuery<EmployeeQualificationHistoryPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption, enabled: false}
    );

    return { data, isLoading, error, refetch }
}

export function useGetEmployeeJobTitleHistory(options?: QueryEmployeeJobTitleHistoryDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_JOB_TITLES;
    const request = (options: QueryEmployeeJobTitleHistoryDto) => API.ProcessingCompanyAPI.getEmployeeJobTitleHistory(options);

    const { data, isLoading, error, refetch } = useQuery<EmployeeJobTitleHistoryPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption, enabled: false}
    );

    return { data, isLoading, error, refetch }
}

export function useGetEmployeeNotchHistory(options?: QueryEmployeeNotchHistoryDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_NOTCHES;
    const request = (options: QueryEmployeeNotchHistoryDto) => API.ProcessingCompanyAPI.getEmployeeNotchHistory(options);

    const { data, isLoading, error, refetch } = useQuery<EmployeeNotchHistoryPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption, enabled: false}
    );

    return { data, isLoading, error, refetch }
}

export function useGetEmployeeBandHistory(options?: QueryEmployeeBandHistoryDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_BANDS;
    const request = (options: QueryEmployeeBandHistoryDto) => API.ProcessingCompanyAPI.getEmployeeBandHistory(options);

    const { data, isLoading, error, refetch } = useQuery<EmployeeBandHistoryPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption, enabled: false}
    );

    return { data, isLoading, error, refetch }
}

export function useGetEmployeeBasicSalaryHistory(options?: QueryEmployeeBasicSalaryHistoryDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_BASIC_SALARY;
    const request = (options: QueryEmployeeBasicSalaryHistoryDto) => API.ProcessingCompanyAPI.getEmployeeBasicSalaryHistory(options);

    const { data, isLoading, error, refetch } = useQuery<EmployeeBasicSalaryHistoryPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption, enabled: false}
    );

    return { data, isLoading, error, refetch }
}

export function useGetEmployeeEngagements(options?: QueryEmployeeEngagementDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_ENGAGEMENTS;
    const request = (options: QueryEmployeeEngagementDto) => API.ProcessingCompanyAPI.getEmployeeEngagement(options);

    const { data, isLoading, error, refetch } = useQuery<EmployeeEngagementPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption, enabled: false}
    );

    return { data, isLoading, error, refetch }
}

export function useGetEmployeeDisengagements(options?: QueryEmployeeDisengagementDto) {
    const url = ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_DISENGAGEMENTS;
    const request = (options: QueryEmployeeDisengagementDto) => API.ProcessingCompanyAPI.getEmployeeDisengagement(options);

    const { data, isLoading, error, refetch } = useQuery<EmployeeDisengagementPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}