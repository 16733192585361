import { GridColDef } from "@mui/x-data-grid";
import { formatDateAndTime, moneyFormat } from "../../../helpers/appHelpers";

export const EmployeeEngagementHistoryColumns: GridColDef[] = [
	{
		field: "recordedAt",
		headerName: "Recorded At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "jobTitle",
		headerName: "Job Title",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "contractType",
		headerName: "Contract Type",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "basicSalary",
		headerName: "Basic Salary",
		width: 400,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.value;
			const currency = params.row.currency;
			return amount ? `${currency?.code || ""} ${moneyFormat(amount)}` : "--";
		},
	},
];

export const EmployeeBasicSalaryHistoryColumns: GridColDef[] = [
	{
		field: "doneAt",
		headerName: "Done At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "previousValue",
		headerName: "Previous Value",
		width: 550,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.value;
			const currency = params.row.previousCurrency;
			return amount ? `${currency?.code || ""} ${moneyFormat(amount)}` : "--";
		},
	},
	{
		field: "currentValue",
		headerName: "Current Value",
		width: 550,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.value;
			const currency = params.row.currentCurrency;
			return amount ? `${currency?.code || ""} ${moneyFormat(amount)}` : "--";
		},
	},
];

export const EmployeeJobTitleHistoryColumns: GridColDef[] = [
	{
		field: "doneAt",
		headerName: "Done At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "previousValue",
		headerName: "Previous Value",
		width: 550,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "currentValue",
		headerName: "Current Value",
		width: 550,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
];

export const EmployeeBandHistoryColumns: GridColDef[] = [
	{
		field: "doneAt",
		headerName: "Done At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "previousValue",
		headerName: "Previous Value",
		width: 550,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "currentValue",
		headerName: "Current Value",
		width: 550,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
];

export const EmployeeNotchHistoryColumns: GridColDef[] = [
	{
		field: "doneAt",
		headerName: "Done At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "previousValue",
		headerName: "Previous Value (Employee Band)",
		width: 550,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code + `(${params.value?.employeeBand?.name})`,
	},
	{
		field: "currentValue",
		headerName: "Current Value (Employee Band)",
		width: 550,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code + `(${params.value?.employeeBand?.name})`,
	},
];

export const EmployeeQualificationHistoryColumns: GridColDef[] = [
	{
		field: "doneAt",
		headerName: "Done At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "previousValue",
		headerName: "Previous Value",
		width: 550,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.qualification,
	},
	{
		field: "currentValue",
		headerName: "Current Value",
		width: 550,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.qualification,
	},
];