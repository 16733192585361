import { useUpdateEffect } from "ahooks";
import { SetStateAction, useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";

interface APISearchInputProps {
	fallbackData: any[];
	setTableData: React.Dispatch<SetStateAction<any>>;
	mutate: any;
	mutateOptions?: any;
	attributes?: React.InputHTMLAttributes<HTMLInputElement>;
	popupTable?: boolean;
	newSearchValue?: string;
}
const APISearchInput = ({
	fallbackData,
	setTableData,
	mutate,
	mutateOptions,
	attributes,
	popupTable,
	newSearchValue,
}: APISearchInputProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const view = searchParams.get("view");
	const clearSearch = searchParams.get("clearSearch");
	const [inputValue, setInputValue] = useState("");
	const [dataSample, setDataSample] = useState<any[]>([]);

	useEffect(() => {
		if (clearSearch) {
			const currentParams = new URLSearchParams(window.location.search);
			currentParams.delete("clearSearch");
			setSearchParams(currentParams);
			setInputValue("");
		}
	}, [clearSearch])

	useUpdateEffect(() => {
		setInputValue(newSearchValue as string);
	}, [newSearchValue])

	const search = () => {
		if (!inputValue.trim()) {
			setTableData(fallbackData);

			if (!popupTable) {
				const currentParams = new URLSearchParams(window.location.search);
				currentParams.delete("searching");
				setSearchParams(currentParams);
			}

			return;
		}

		mutate(
			{ q: inputValue.trim(), page: 1, ...mutateOptions },
			{
				onSuccess: (data: any) => {
					setTableData(data.data);
					setDataSample(data.data);

					if (popupTable) return;

					if (view) {
						setSearchParams({ 
							view, 
							page: "1",
							searching: "true"
						});

						return
					} 
					setSearchParams({ page: "1", searching: "true" });
				}
			}
		)
	};

	useUpdateEffect(() => search(), [inputValue])

	useUpdateEffect(() => {
		if (!dataSample.length && !inputValue.trim()) {
			setTableData(fallbackData);
		}
	}, [dataSample])

    return (  
		<div className="relative">
			<input
				type="text"
				placeholder="Search"
				className="api-search-input w-full bg-secondary-gray rounded-lg py-3 pl-12 pr-4 text-sm"
				value={inputValue}
				onChange={(e) => setInputValue(e.target.value)}
				{ ...attributes }
			/>
			<FiSearch className="absolute top-1/2 -translate-y-1/2 left-5 text-gray-400 text-lg" />
		</div>
    );
}
 
export default APISearchInput;