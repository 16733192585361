import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import Label from "../../../../../components/form_fields/Label";
import ModalComponent from "../../../../../components/modals/ModalFormComponent";
import { IAddEditTableForm } from "../../../../../helpers/appHelpers";
import useEditFormStore from "../../../../../state-management/useEditFormStore";
import { useCreateHoliday, useUpdateHoliday } from "../../../../../services/holiday.service";
import { HOLIDAY_TYPE_ENUM } from "../../../../../models/holiday.dto";
import useUserStore from "../../../../../state-management/useUserStore";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	code: string;
    description?: string;
    type: HOLIDAY_TYPE_ENUM
    name: string;
    date: string;
}

type HolidayModalFormProps = IAddEditTableForm & {
	addNewData: () => void;
}

const HolidayModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	addNewData,
}: HolidayModalFormProps) => {
	const { currentUser } = useUserStore();
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			description: isEditForm ? formDataValues?.description : "",
			type: isEditForm ? formDataValues?.type : "",
			date: isEditForm ? formDataValues?.date?.slice(0, 10) : "",
		}
	});

	const { mutate: createHoliday } = useCreateHoliday();
	const { mutate: updateHoliday } = useUpdateHoliday();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateHoliday(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createHoliday(
				{ 
					payload: { 
						...data, 
						organizationId: currentUser.user.organizationId
					} 
				}, 
				{
					onSuccess: () => {
						addNewData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)} className="mt-4">
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" optional />
						<FormDescriptionInput
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
							required={false}
						/>
					</div>
					<div className="w-full">
						<Label title="Type" for="type" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.type
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("type", {
								required: "Select type here",
							})}
						>
							<option value="">Select Type</option>
							<option value="PUBLIC_HOLIDAY">PUBLIC HOLIDAY</option>
							<option value="WEEKEND">WEEKEND</option>
						</select>
						{errors.type && (
							<p className="text-red-500 text-sm mt-1">
								{errors.type.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Date" for="date" />
						<input
							defaultValue={isEditForm ? formDataValues?.date?.slice(0, 10) : ""}
							type="date"
							{...register("date", {
								required: "Enter date here",
							})}
							id="date"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.date
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.date && (
							<p className="text-red-500 text-sm mt-1">
								{errors.date.message}
							</p>
						)}
					</div>
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={
							uploading
							? "Uploading..."
							: isEditForm
								? "Update Holiday"
								: "Add New Holiday"
						}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default HolidayModalForm;
