import { useEffect, useState } from "react";
import Profile from "./nav/profile";
import TeamMembers from "./nav/team_members";
import Subscription from "./nav/subscription";
import Disabilities from "./nav/disabilities";
import ContractTypes from "./nav/contract_types";
import Roles from "./nav/roles";
import { Box, Modal } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import Holidays from "./holidays";
import useUserStore from "../../../state-management/useUserStore";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 1260,
	maxWidth: "95vw",
	height: 850,
	bgcolor: "background.paper",
	boxShadow: 24,
	// p: 4,
	border: "none",
	borderRadius: "8px",
	outline: "none",
	overflow: "auto",
	maxHeight: "90vh",
	display: "flex",
	flexDirection: "column",
};

const AccountSettingsModalForm = ({
	open,
	setOpen,
	title,
}: any) => {
	const currentOrganization = useUserStore().currentUser.user.organization;
	const pathname = window.location.pathname;
	const [view, setView] = useState("Profile");

	useEffect(() => {
		const scrollableSection = document.getElementById("scrollable") as HTMLDivElement;
		if (scrollableSection) scrollableSection.scrollTo(0, 0);
	}, [view])

	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div className="flex justify-between items-center px-9 mt-9 mb-4">
					<h1 className="font-bold text-lg">{title}</h1>
					<button onClick={() => setOpen(false)}>
						<IoMdClose className="text-xl hover:scale-[1.1]" />
					</button>
				</div>
				<div className="grow overflow-auto px-9 mb-9 mt-8 flex space-x-4 scroll-smooth">
					<div className="w-[25%] space-y-2 sticky top-0">
						{pathname.startsWith("/dashboard") ? (
							<>
								{(currentOrganization?.billingType.name.toLowerCase() === "contract"
									? AccountSettingsNavLinksContractOrg
									: AccountSettingsNavLinksAll
								).map((item) => (
									<div
										onClick={() => setView(item)}
										key={item}
										className={`flex flex-col pl-4 h-[42px] w-[80%] text-sm rounded-lg justify-center hover:cursor-pointer ${
											view === item
												? " bg-primary-blue text-white hover:opacity-90"
												: "bg-white text-black hover:bg-gray-200"
										}`}
									>
										<p>{item}</p>
									</div>
								))}
							</>
						) : (
							<>
								{AccountSettingsNavLinksAll.slice(0, 1).map((item) => (
									<div
										onClick={() => setView(item)}
										key={item}
										className={`flex flex-col pl-4 h-[42px] w-[80%] text-sm rounded-lg justify-center hover:cursor-pointer ${
											view === item
												? " bg-primary-blue text-white hover:opacity-90"
												: "bg-white text-black hover:bg-gray-200"
										}`}
									>
										<p>{item}</p>
									</div>
								))}
							</>
						)}
					</div>
					<div className="w-[75%]">
						{view === "Profile" && <Profile />}
						{view === "Team Members" && <TeamMembers />}
						{view === "Roles" && <Roles />}
						{view === "Subscription" && <Subscription closeModal={() => setOpen(false)} />}
						{view === "Holidays" && <Holidays />}
						{view === "Disabilities" && <Disabilities />}
						{view === "Contract Types" && <ContractTypes />}
					</div>
				</div>
			</Box>
		</Modal>
	);
};

export default AccountSettingsModalForm;

const AccountSettingsNavLinksAll = [
	"Profile",
	"Roles",
	"Team Members",
	"Subscription",
	"Holidays",
	"Disabilities",
	"Contract Types",
];
const AccountSettingsNavLinksContractOrg = [
	"Profile",
	"Roles",
	"Team Members",
	"Holidays",
	"Disabilities",
	"Contract Types",
];
