import {HttpClient} from "../requests";
import ENDPOINTS from "../endpoints";
import { CreatePayrollDto, CreatePayrollObject, PayPeriod, PayrollRuns, PayrollRunsPaginator, PayrollRunStatusObject, PayslipPaginator, QueryCompanyPayslipsDto, QueryEmployeePayslipsDto, QueryParam, QueryPayrollDto } from "../../../models/payroll.dto";

export class Payroll {
    getEmployeePayslips = (employeeId: number, query?: QueryParam) => HttpClient
        .get<PayslipPaginator>(ENDPOINTS.PAYROLL.GET_EMPLOYEE_PAYSLIPS
            .replace('{employeeId}', String(employeeId)), query);
    getCompanyPayslips = (companyId: number, payPeriodId: number, query?: QueryCompanyPayslipsDto) => HttpClient
        .get<PayslipPaginator>(ENDPOINTS.PAYROLL.GET_COMPANY_PAYSLIPS
            .replace('{companyId}', String(companyId))
            .replace('{payPeriodId}', String(payPeriodId)), query);
    getPayrollRunParams = (payrollId: number, query: QueryParam) => HttpClient
        .get<{ data: PayrollRuns }>(ENDPOINTS.PAYROLL.GET_PAYROLL_RUN_PARAMS
            .replace('{payrollId}', String(payrollId)), query);
    getCompanyPayrollRuns = (query?: QueryPayrollDto) => HttpClient
        .get<PayrollRunsPaginator>(ENDPOINTS.PAYROLL.GET_COMPANY_PAYROLL_RUNS, query);
    runPayrollParams = (payrollRunId: number, query: QueryParam) => HttpClient
        .get<{ data: string }>(ENDPOINTS.PAYROLL.RUN_PAYROLL
            .replace('{payrollRunId}', String(payrollRunId)), query);
    createPayrollRun = (payload: CreatePayrollDto, query: QueryParam) => HttpClient
        .post<{ data: CreatePayrollObject }>(ENDPOINTS.PAYROLL.CREATE_PAYROLL_RUN, payload, query);
    
    getPayrollExcell = (companyId: number, payPeriodId: number, query: QueryParam) => HttpClient
        .get<any>(ENDPOINTS.PAYROLL.GET_PAYROLL_EXCELL
            .replace('{companyId}', String(companyId))
            .replace('{payPeriodId}', String(payPeriodId)), query);
    getRecentPayrollRun = (companyId: number, query: QueryParam) => HttpClient
        .get<{ data: PayPeriod }>(ENDPOINTS.PAYROLL.GET_RECENT_PAYROLL_RUN.replace('{companyId}', String(companyId)), query);
    getEmployeePayslip = (query: QueryEmployeePayslipsDto) => HttpClient
        .get<{ data: string }>(ENDPOINTS.PAYROLL.GET_EMPLOYEE_PAYSLIP, query);
    getPayrollRunStatus = (payrollRunId: number, query: QueryParam) => HttpClient
        .get<{ data: PayrollRunStatusObject }>(ENDPOINTS.PAYROLL.GET_PAYROLL_RUN_STATUS
            .replace('{payrollRunId}', String(payrollRunId)), query);
    }