import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, enumToStringConverter, formatDate, moneyFormat } from "../../../helpers/appHelpers";
import { Avatar } from "@mui/material";
import { getLeaveRequestStatusColor } from "../../self_service/leave_management/data";

export const LeaveRequestsColumnsHR: GridColDef[] = [
	{
		field: "employee",
		headerName: "Employee",
		width: 250,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-4">
					<Avatar
						src={params.value?.photoUrl}
						alt="Dp"
						className="w-10 h-10 rounded-full"
					/>
					<p>{(params.value?.firstName || "") + " " + (params.value?.lastName || "")}</p>
				</div>
			);
		},
	},
	{
		field: "startDate",
		headerName: "Start Date",
		width: 200,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const startDate = formatDate(params);
			return startDate;
		},
	},
	{
		field: "returnDate",
		headerName: "Return Date",
		width: 200,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const returnDate = formatDate(params);
			return returnDate;
		},
	},
	{
		field: "status",
		headerName: "Status",
		width: 200,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getLeaveRequestStatusColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getLeaveRequestStatusColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p>{enumToStringConverter(params.value)}</p>
				</div>
			);
		},
	},
	{
		field: "comment",
		headerName: "Comment",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "leavePackage",
		headerName: "Leave Type",
		headerClassName: "super-app-theme--header",
		width: 220,
		renderCell(params) {
			const leaveType = params.row.leavePackage?.leaveType;

			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: leaveType?.colorCode }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: leaveType?.colorCode, 
							opacity: "15%"
						}}
					/>
					<p>{leaveType?.name}</p>
				</div>
			);
		},
	},
	
	...createdAtmodifiedAtActions
];

export const LeavePackageColumns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 250,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "leaveType",
		headerName: "Leave Type",
		headerClassName: "super-app-theme--header",
		width: 200,
		renderCell(params) {
			const leaveType = params.row.leaveType;

			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: leaveType?.colorCode }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: leaveType?.colorCode, 
							opacity: "15%"
						}}
					/>
					<p>{leaveType?.name}</p>
				</div>
			);
		},
	},
	{
		field: "maxDays",
		headerName: "Max Days",
		width: 180,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "paid",
		headerName: "Paid",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "redeemable",
		headerName: "Redeemable",
		width: 150,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "accrued",
		headerName: "Accrued",
		width: 150,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "carryOverDaysValue",
		headerName: "Carry Over Days Amount",
		width: 260,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "carryOverDaysPercent",
		headerName: "Carry Over Days Percentage",
		width: 260,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value || "--",
	},
	
	...createdAtmodifiedAtActions
];

export const LeavePackageColumnVisibilityModel: Record<string, boolean> = {
	name: true,
	code: true,
	description: true,
	leaveType: true,
	maxDays: true,
	paid: true,
	redeemable: true,
	accrued: true,
	carryOverDaysValue: false,
	carryOverDaysPercent: false,
	createdAt: true,
	modifiedAt: true,
};
