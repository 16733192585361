import { Box, Modal } from "@mui/material";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { useRunPayrollParams } from "../../../../services/payrol.service";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";

type RunPayrollModalProps = {
	openModal: boolean;
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const RunPayrollModal = ({
	openModal,
	setOpenModal,
}: RunPayrollModalProps) => {
	const { formDataValues } = useEditFormStore();
	const { mutate: runPayroll, isLoading } = useRunPayrollParams();
	const { reloadTableData } = useManageTableWithSearchParamsData();

	const handleRunPayroll = () => {
		runPayroll(
			{ id: formDataValues?.id, options: {} },
			{ onSuccess: () => {
				reloadTableData();
				setOpenModal(false);
			}}
		);
	};

	return (
		<Modal open={openModal}>
			<Box sx={{
				position: "absolute" as "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: 450,
				py: 4,
				bgcolor: "background.paper",
				boxShadow: 24,
				border: "none",
				borderRadius: "8px",
				outline: "none",
				overflow: "auto",
				maxHeight: "95vh",
				display: "flex",
				flexDirection: "column",
			}}>
				<div className="w-full px-6 lg:px-8">
					<div className="flex justify-center">
						<h3 className="text-xl font-bold">Run Payroll</h3>
					</div>
                    <p className="text-2sm text-center mt-6">
                        Are you sure you want to run this payroll?
                    </p>
					<div className="max-lg:w-full mt-8 flex justify-center space-x-4 whitespace-nowrap text-center">
						<button
							title={"Cancel"}
							onClick={() => setOpenModal(false)}
							className={`lg:px-14 max-lg:w-full text-gray-400 bg-white border rounded-lg
								border-secondary-lightgray text-2xs ${isLoading && "opacity-50"}
							`}
							disabled={isLoading}
						>
							Cancel
						</button>
						<OrangeButton
							onClick={handleRunPayroll}
							title={isLoading ? "Running..." : "Yes, Run"}
							className={"lg:px-14 max-lg:w-full"}
							disabled={isLoading}
						/>
					</div>
				</div>
			</Box>
		</Modal>
	);
};

export default RunPayrollModal;
