import { PaginatorInfo } from "./generic.dto";
import { EmployeePayInfo } from "./employee-pay-info.dto";
import { EmployeeAccountInfo } from "./employee-account-info.dto";
import { JobTitleDto } from "./job-titles.dto";
import { EmployeeContractDto } from "./employee-contract.dto";
import { CompanyGroupingDto } from "./company-grouping.dto";
import { GradeLevelDto } from "./grade-level.dto";
import { DepartmentDto } from "./department.dto";
import { CostCenterDto } from "./cost-center.dto";

export const GENDER = {
	MALE: "MALE",
	FEMALE: "FEMALE",
};

export type GENDER = (typeof GENDER)[keyof typeof GENDER];

export const PAY_PERIOD = {
	HOUR: "HOUR",
	DAY: "DAY",
	WEEK: "WEEK",
	MONTH: "MONTH",
	QUARTER: "QUARTER",
	YEAR: "YEAR",
};

export type PAY_PERIOD = (typeof PAY_PERIOD)[keyof typeof PAY_PERIOD];

export const EMPLOYEE_PAY_INFO_TYPE = {
	WALLET: "WALLET",
	BANK: "BANK",
};

export type EMPLOYEE_PAY_INFO_TYPE =
	(typeof EMPLOYEE_PAY_INFO_TYPE)[keyof typeof EMPLOYEE_PAY_INFO_TYPE];

export const EMPLOYEE_ACCOUNT_INFO_TYPE = {
	WALLET: "WALLET",
	BANK: "BANK",
};

export type EMPLOYEE_ACCOUNT_INFO_TYPE =
	(typeof EMPLOYEE_ACCOUNT_INFO_TYPE)[keyof typeof EMPLOYEE_ACCOUNT_INFO_TYPE];

export const STATUS = {
	ACTIVE: "ACTIVE",
	ELAPSED_CONTRACT: "ELAPSED_CONTRACT",
	TERMINATED: "TERMINATED",
	DISABLED: "DISABLED",
	ARCHIVED: "ARCHIVED",
};

export type STATUS = (typeof STATUS)[keyof typeof STATUS];

export class CreateEmployeePayInfoDto {
    basicPay!: number;
    payPeriod!: PAY_PERIOD;
    splitPayment!: boolean;
    primaryPayPercent!: number;
    primaryPaymentChannel!: EMPLOYEE_PAY_INFO_TYPE;
    secondaryPaymentChannel?: EMPLOYEE_PAY_INFO_TYPE;
    currencyId!: number;
}

export class UpdateEmployeePayInfoDto {
	basicPay?: number;
	payPeriod?: PAY_PERIOD;
	splitPayment?: boolean;
	primaryPayPercent?: number;
	primaryPaymentChannel?: EMPLOYEE_PAY_INFO_TYPE;
	secondaryPaymentChannel?: EMPLOYEE_PAY_INFO_TYPE;
	currencyId?: number;
}

export class CreateEmployeeAccountInfoDto {
	type!: EMPLOYEE_ACCOUNT_INFO_TYPE;
	institutionId!: number;
	institutionBranchId!: number;
	accountNumber!: string;
	primary?: boolean;
	secondary?: boolean;
	active!: boolean;
	currencyId?: number;
}

export class UpdateEmployeeAccountInfoDto {
	id!: number;
	employeeId?: number;
	type?: EMPLOYEE_ACCOUNT_INFO_TYPE;
	institutionId?: number;
	institutionBranchId?: number;
	accountNumber?: string;
	primary?: boolean;
	secondary?: boolean;
	active?: boolean;
	currencyId?: number;
}

export class CreateEmployeeDto {
	companyId!: number;
	notchId?: number;
	employeeNumber!: string;
	title!: string;
	firstName!: string;
	lastName!: string;
	otherNames?: string;
	gender!: GENDER;
	dateOfBirth!: string;
	photoUrl?: string;
	ssn!: string;
	taxIdentificationNumber?: string;
	majorGradeLevelId?: number;
	minorGradeLevelId?: number;
	nationality!: string;
	regionId?: number;
	tribeId?: number;
	email?: string;
	privateEmail?: string;
	msisdn?: string;
	address?: string;
	alternateMsisdn?: string;
	digitalAddress?: string;
	jobTitleId?: number;
	departmentId?: number;
	divisionId?: number;
	stationId?: number;
	costAreaId?: number;
	status!: STATUS;
	employmentDate!: string;
	terminationDate?: string;
	reemployed!: boolean;
	resident?: boolean;
	unionMember?: boolean;
	employeePayInfo?: CreateEmployeePayInfoDto;
	employeeAccountInfo?: CreateEmployeeAccountInfoDto[];
	hr?: boolean;
	username?: string;
	contract?: {
		contractTypeId: number;
		contractNumber?: string;
		startDate?: Date;
	}
}

export class UpdateEmployeeDto {
	notchId?: number;
	employeeNumber?: string;
	title?: string;
	firstName?: string;
	lastName?: string;
	otherNames?: string;
	gender?: GENDER;
	dateOfBirth?: string;
	photoUrl?: string;
	ssn?: string;
	taxIdentificationNumber?: string;
	majorGradeLevelId?: number;
	minorGradeLevelId?: number;
	nationality?: string;
	regionId?: number;
	tribeId?: number;
	email?: string;
	privateEmail?: string;
	msisdn?: string;
	address?: string;
	alternateMsisdn?: string;
	digitalAddress?: string;
	jobTitleId?: number;
	departmentId?: number;
	divisionId?: number;
	stationId?: number;
	costAreaId?: number;
	status?: STATUS;
	employmentDate?: string;
	terminationDate?: string;
	reemployed?: boolean;
	resident?: boolean;
	unionMember?: boolean;
	employeePayInfo?: UpdateEmployeePayInfoDto;
	employeeAccountInfo?: UpdateEmployeeAccountInfoDto[];
	hr?: boolean;
}

export interface EmployeeDto extends Omit<CreateEmployeeDto, "employeeAccountInfo" | "employeePayInfo"> {
	id: number;
	employeePayInfo: EmployeePayInfo;
	username?: string;
	employeeAccountInfo: EmployeeAccountInfo[];
	jobTitle?: JobTitleDto | null;
	employeeContracts?: EmployeeContractDto[];
	tribe?: CompanyGroupingDto,
	division?: CompanyGroupingDto,
	station?: CompanyGroupingDto,
	region?: CompanyGroupingDto,
	majorGradeLevel?: GradeLevelDto,
	minorGradeLevel?: GradeLevelDto,
	department?: DepartmentDto,
	costCentre?: CostCenterDto,
}

export class UploadEmployeeViaSpreadsheetDto {
	rowNumber?: number;
	companyId!: number;
	employeeNumber!: string;
	notchCode!: string;
	firstName!: string;
	lastName!: string;
	otherNames?: string;
	title!: string;
	gender!: GENDER;
	dob!: string;
	photoUrl?: string;
	ssn!: string;
	tin?: string;
	gradeMajorLevelCode?: string;
	gradeMinorLevelCode?: string;
	nationalityCode!: string;
	regionCode!: string;
	resident!: "Yes" | "No";
	splitPayment!: "Yes" | "No";
	primaryPayPercentage!: number;
	primaryPaymentChannel!: "BANK" | "WALLET";
	primaryAccountInstitutionCode!: string;
	primaryAccountBranchSortCode!: string;
	primaryAccountNumber!: string;
	secondaryPaymentChannel!: "BANK" | "WALLET";
	secondaryAccountInstitutionCode!: string;
	secondaryAccountBranchSortCode!: string;
	secondaryAccountNumber!: string;
	emailAddress!: string;
	privateEmailAddress!: string;
	phoneNumber!: string;
	alternatePhoneNumber!: string;
	employmentDate!: string;
	terminationDate?: string;
	reemployed!: "Yes" | "No";
	departmentCode!: string;
	divisionCode!: string;
	stationCode!: string;
	jobTitle!: string;

	straightShiftWorker!: 'Yes' | 'No';
	firstCategoryLevel?: number;
	secondCategoryLevel?: number;
	thirdCategoryLevel?: number;
	fourthCategoryLevel?: number;
	fifthCategoryLevel?: number;
	currency?: string;
	basicPay!: number;
	unionMember!: 'Yes' | 'No';

	hr?: 'Yes' | 'No';
	contractTypeCode?: string;
	contractNumber?: string;
	contractStartDate?: Date;
}

export class QueryEmployeeDto {
	companyId?: number;
	employeeNumber?: string;
	notchId?: number;
	gender?: GENDER;
	majorGradeLevelId?: number;
	minorGradeLevelId?: number;
	jobTitleId?: number;
	status?: STATUS;
	hr?: boolean;
	'dateOfBirth.gte'?: string;
	'dateOfBirth.lte'?: string;
	'employmentDate.gte'?: string;
	'employmentDate.lte'?: string;
	'terminationDate.gte'?: string;
	'terminationDate.lte'?: string;
	'createdAt.gte'?: string;
	'createdAt.lte'?: string;
	reemployed?: boolean;
	resident?: boolean;
	unionMember?: boolean;
	page?: number = 1;
	limit?: number;
	orderBy?: EmployeeOrderBy;
}

export class CountEmployeeDto {
	organizationId?: string;
	companyId?: number;
	employeeBandId?: number;
	notchId?: number;
	gender?: GENDER;
	majorGradeLevelId?: number;
	minorGradeLevelId?: number;
	//  departmentCode?: string;
	jobTitleId?: number;
	status?: STATUS;
	'dateOfBirth.gte'?: string;
	'dateOfBirth.lte'?: string;
	'employmentDate.gte'?: string;
	'employmentDate.lte'?: string;
	'terminationDate.gte'?: string;
	'terminationDate.lte'?: string;
	'createdAt.gte'?: string;
	'createdAt.lte'?: string;
	reemployed?: boolean;
	resident?: boolean;
	unionMember?: boolean;
	//  stationCode?: string;
  }

export interface EmployeePaginator extends PaginatorInfo<EmployeeDto> {}

export enum EmployeeOrderBy {
	CREATED_AT_ASC = 'createdAt:asc',
	CREATED_AT_DESC = 'createdAt:desc',
	MODIFIED_AT_ASC = 'modifiedAt:asc',
	MODIFIED_AT_DESC = 'modifiedAt:desc',
	FIRST_NAME_ASC = 'firstName:asc',
	FIRST_NAME_DESC = 'firstName:desc',
	LAST_NAME_ASC = 'lastName:asc',
	LAST_NAME_DESC = 'lastName:desc',
  }

export class SearchEmployeeDto {
	q?: string
	page: number = 1;
	limit?: number;
	orderBy: EmployeeOrderBy = EmployeeOrderBy.CREATED_AT_ASC;
  }

export class UploadEmployeeResponse {
	successful!: {
		employeeId?: number,
		rowNumber?: number,
		accountCreationErroMessage?: string, 
		accountCreationInitiated?: boolean,
	}[];

	failed!: {
		rowNumber?: number,
		errors: {
		  column: string,
		  reason: string,
		}[]
	}[]
}

export class EmployeeTerminationDto {
	terminationDate!: Date;
	reason?: string;
}