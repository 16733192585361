import { Typography, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { IoCaretDown } from "react-icons/io5";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { APP_CONFIG, CustomizedTooltip } from "../../../../helpers/appHelpers";
import { EmployeeDto, EmployeeOrderBy, GENDER, STATUS } from "../../../../models/employee.dto";
import { 
    useGetEmployeeEngagements, 
    useGetEmployeeBasicSalaryHistory, 
    useGetEmployeeJobTitleHistory, 
    useGetEmployeeBandHistory, 
    useGetEmployeeNotchHistory, 
    useGetEmployeeQualificationHistory 
} from "../../../../services/employee-history.service";
import { useGetEmployee, useGetEmployeeSearch } from "../../../../services/employee.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import { 
    EmployeeEngagementHistoryColumns, 
    EmployeeBasicSalaryHistoryColumns, 
    EmployeeJobTitleHistoryColumns, 
    EmployeeBandHistoryColumns, 
    EmployeeNotchHistoryColumns, 
    EmployeeQualificationHistoryColumns 
} from "../data";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { useUpdateEffect } from "ahooks";
import APISearchInput from "../../../../components/search/APISearchInput";
import { useSearchParams } from "react-router-dom";
import { generateFilter } from "../../../../helpers/useManageTable";


const EmployeesHistory = () => {
	const { currentCompany } = useCompanyStore();
	const [searchParams] = useSearchParams();
	const searching = searchParams.get("searching");
	const [employeesAnchorEl, setEmployeesAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [searchedEmployeesModal, setSearchedEmployeesModal] = useState<boolean>(false);
	const [activeEmployee, setActiveEmployee] = useState<EmployeeDto>();
	const [searchedEmployees, setSearchedEmployees] = useState<EmployeeDto[]>([]);
	const [searchedEmployee, setSearchedEmployee] = useState("");
	const [employeesFilter, setEmployeesFilter] = useState({});

	const { 
        mutate: searchEmployees, 
        isLoading: searchingEmployees 
    } = useGetEmployeeSearch();
	const { 
        data: employees, 
        isLoading: loadingEmployees, 
        refetch: refetchEmployees, 
    } = useGetEmployee({ 
		companyId: currentCompany?.id,
		limit: 100,
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
        ...generateFilter(new URLSearchParams(employeesFilter)),
	});

	useUpdateEffect(() => {
		if (Object.entries(employeesFilter).length) {
			refetchEmployees();
            setActiveEmployee(undefined);
		}
	}, [employeesFilter]);
	
	const { 
		data: employeeEngagementHistory, 
		isLoading: loadingEmployeeEngagementHistory,
		refetch: refetchEmployeeEngagementHistory, 
	} = useGetEmployeeEngagements({ 
		employeeId: activeEmployee?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "recordedAt:desc" as any,
	});
	const { 
		data: employeeBasicSalaryHistory, 
		isLoading: loadingEmployeeBasicSalaryHistory,
		refetch: refetchEmployeeBasicSalaryHistory, 
	} = useGetEmployeeBasicSalaryHistory({ 
		employeeId: activeEmployee?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "doneAt:desc" as any,
	});
	const { 
		data: employeeJobTitleHistory, 
		isLoading: loadingEmployeeJobTitleHistory,
		refetch: refetchEmployeeJobTitleHistory, 
	} = useGetEmployeeJobTitleHistory({ 
		employeeId: activeEmployee?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "doneAt:desc" as any,
	});
	const { 
		data: employeeBandHistory, 
		isLoading: loadingEmployeeBandHistory,
		refetch: refetchEmployeeBandHistory, 
	} = useGetEmployeeBandHistory({ 
		employeeId: activeEmployee?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "doneAt:desc" as any,
	});
	const { 
		data: employeeNotchHistory, 
		isLoading: loadingEmployeeNotchHistory,
		refetch: refetchEmployeeNotchHistory, 
	} = useGetEmployeeNotchHistory({ 
		employeeId: activeEmployee?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "doneAt:desc" as any,
	});
	const { 
		data: employeeQualifciationHistory, 
		isLoading: loadingEmployeeQualificationHistory,
		refetch: refetchEmployeeQualificationHistory, 
	} = useGetEmployeeQualificationHistory({ 
		employeeId: activeEmployee?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "doneAt:desc" as any,
	});

	useUpdateEffect(() => {
		if (activeEmployee) {
			refetchEmployeeEngagementHistory();
			refetchEmployeeBasicSalaryHistory();
			refetchEmployeeJobTitleHistory();
			refetchEmployeeBandHistory();
			refetchEmployeeQualificationHistory();
			refetchEmployeeNotchHistory();
		}
	}, [activeEmployee]);
	
	return (
		<>
            <div className="flex justify-between items-center mt-4 mb-8">
                <div className="flex items-center gap-6">
                    <div className="relative">
                        <APISearchInput 
                            fallbackData={[]}
                            setTableData={setSearchedEmployees}
                            mutate={searchEmployees}
                            newSearchValue={searchedEmployee}
                            attributes={{
                                placeholder: "Search employees",
                                onInput: (e: any) => {
                                    if (searchingEmployees) return;
                                    setSearchedEmployeesModal(true);
                                }
                            }}
                        />
                        {Boolean(searchedEmployeesModal && searching) && (
                            <div className="absolute left-0 top-[45px] h-auto max-h-[500px] py-1 text-2sm text-[#4F4F4F] rounded-[10px] bg-white 
                                shadow-[-10px_-5px_30px_0px_rgba(0,0,0,0.15)] flex flex-col overflow-y-auto children-scroll-bar"
                            >
                                {!searchedEmployees.length ? <p className="pl-4 pr-7 py-2">No Employees found</p> : null}

                                {searchedEmployees.map((employee) => (
                                    <button 
                                        key={employee.id}
                                        className={`w-full pl-4 pr-7 py-2 text-start text-gray-700 
                                            ${activeEmployee?.id === employee.id 
                                                ? "bg-gray-200" 
                                                : "hover:bg-gray-100"
                                            }
                                        `} 
                                        role="menuitem"
                                        onClick={() => {
                                            setActiveEmployee(employee);
                                            setSearchedEmployeesModal(false);
                                            setSearchedEmployee(employee.firstName + " " + employee.lastName);
                                        }}
                                    >
                                        {employee.firstName + " " + employee.lastName}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="flex items-center gap-3">
                        <>
                            <button
                                onClick={(e: any) => {
                                    if (loadingEmployees) return;
                                    setEmployeesAnchorEl(e.currentTarget);
                                }}
                                className={`relative border border-gray-300 rounded-lg text-2xs py-3 px-3 flex items-center gap-8 
                                    ${(searchingEmployees || loadingEmployees) ? "opacity-60 cursor-not-allowed" : ""}
                                `}
                            >
                                <div className="w-fit absolute top-0 -translate-y-1/2 left-2 px-2 bg-white text-[11px] flex items-center gap-1">
                                    <span>Employee</span>
                                    <CustomizedTooltip 
                                        title="Only the first 100 employees (alphabetically) are shown. Use the filter to find specific employees." 
                                        placement="top"
                                        arrow
                                    >
                                        <Typography>
                                            <FaQuestionCircle className="text-black text-[13px]" />
                                        </Typography>
                                    </CustomizedTooltip>
                                </div>
                                {(loadingEmployees) ? (
                                    <span>Fetching Employees...</span>
                                ):(
                                    <>
                                        {activeEmployee?.id ? (
                                            <span className="max-w-full truncate">
                                                {activeEmployee.firstName + " " + activeEmployee.lastName}
                                            </span>
                                        ):(
                                            <span>Select Employee</span>
                                        )}
                                    </>
                                )}
                                {!loadingEmployees && <IoCaretDown />}
                            </button>
                            <Popover
                                open={Boolean(employeesAnchorEl)}
                                anchorEl={employeesAnchorEl}
                                onClose={() => setEmployeesAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                PaperProps={{
                                    style: {
                                        height: "auto",
                                        maxHeight: "500px",
                                        fontSize: "15px",
                                        color: "#4F4F4F",
                                        borderRadius: 10,
                                        background: "white",
                                        boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                                    }
                                }}
                            >
                                <div className="py-1 flex flex-col overflow-y-auto children-scroll-bar">
                                    {!employees?.data?.length ? <p className="pl-4 pr-7 py-2">No Employees found</p> : null}

                                    {employees?.data?.map((employee) => (
                                        <button 
                                            key={employee.id}
                                            className={`w-full pl-4 pr-7 py-2 text-start text-gray-700 
                                                ${activeEmployee?.id === employee.id 
                                                    ? "bg-gray-200" 
                                                    : "hover:bg-gray-100"
                                                }
                                            `} 
                                            role="menuitem"
                                            onClick={() => {
                                                setActiveEmployee(employee);
                                                setEmployeesAnchorEl(null);
                                                setSearchedEmployee("");
                                            }}
                                        >
                                            {employee.firstName + " " + employee.lastName}
                                        </button>
                                    ))}
                                </div>
                            </Popover>
                        </>
                        <TableFilter
                            fields={[
                                {
                                    title: "",
                                    fieldName: "",
                                    fieldType: "SORT",
                                    options: [
                                        {
                                            title: "First Name",
                                            fieldName: "firstName",
                                        },
                                        {
                                            title: "Last Name",
                                            fieldName: "lastName",
                                        },
                                        {
                                            title: "Date Created",
                                            fieldName: "createdAt",
                                        },
                                        {
                                            title: "Date Modified",
                                            fieldName: "modifiedAt",
                                        },
                                    ]
                                },
                                {
                                    title: "Gender",
                                    fieldName: "gender",
                                    fieldType: "CHECKBOX_LIST",
                                    options: GENDER,
                                },
                                {
                                    title: "HR",
                                    fieldName: "hr",
                                    fieldType: "YES_NO",
                                },
                                {
                                    title: "Status",
                                    fieldName: "status",
                                    fieldType: "CHECKBOX_LIST",
                                    options: STATUS,
                                },
                                {
                                    title: "Date Of Birth",
                                    fieldName: "dateOfBirth",
                                    fieldType: "DATE_RANGE",
                                },
                                {
                                    title: "Employment Date",
                                    fieldName: "employmentDate",
                                    fieldType: "DATE_RANGE",
                                },
                                {
                                    title: "Termination Date",
                                    fieldName: "terminationDate",
                                    fieldType: "DATE_RANGE",
                                },
                                {
                                    title: "Date Created",
                                    fieldName: "createdAt",
                                    fieldType: "DATE_RANGE",
                                },
                                {
                                    title: "Resident",
                                    fieldName: "resident",
                                    fieldType: "YES_NO",
                                },
                                {
                                    title: "Reemployed",
                                    fieldName: "reemployed",
                                    fieldType: "YES_NO",
                                },
                                {
                                    title: "Union Member",
                                    fieldName: "unionMember",
                                    fieldType: "YES_NO",
                                },
                            ]}
                            externalFilter={employeesFilter}
                            setExternalFilter={setEmployeesFilter}
                            searchOption
                        />
                    </div>
                </div>
                <OrangeButton
                    title={`Download ${activeEmployee?.id ? (activeEmployee.firstName + "'s") : "Employee"} History`}
                    className="bg-primary-mango text-white h-14 px-6 rounded-lg text-sm"
                    // onClick={() => {
                    //     window.location.href = excelFile;
                    // }}
                    // disabled={fetchingExcelFile}
                />
            </div>
            <section className="flex flex-col gap-10 pb-10">
                <div>
                    <h3 className="font-bold text-base mb-5">Employee Engagement</h3>
                    <CustomStyledTable
                        rows={!activeEmployee?.id ? [] : (employeeEngagementHistory?.data || [])}
                        columns={EmployeeEngagementHistoryColumns}
                        apiRef={null}
                        loading={loadingEmployeeEngagementHistory}
                        pagination={null}
                    />
                </div>
                <div>
                    <h3 className="font-bold text-base mb-5">Basic Salary</h3>
                    <CustomStyledTable
                        rows={!activeEmployee?.id ? [] : (employeeBasicSalaryHistory?.data || [])}
                        columns={EmployeeBasicSalaryHistoryColumns}
                        apiRef={null}
                        loading={loadingEmployeeBasicSalaryHistory}
                        pagination={null}
                    />
                </div>
                <div>
                    <h3 className="font-bold text-base mb-5">Job Title</h3>
                    <CustomStyledTable
                        rows={!activeEmployee?.id ? [] : (employeeJobTitleHistory?.data || [])}
                        columns={EmployeeJobTitleHistoryColumns}
                        apiRef={null}
                        loading={loadingEmployeeJobTitleHistory}
                        pagination={null}
                    />
                </div>
                <div>
                    <h3 className="font-bold text-base mb-5">Employee Band</h3>
                    <CustomStyledTable
                        rows={!activeEmployee?.id ? [] : (employeeBandHistory?.data || [])}
                        columns={EmployeeBandHistoryColumns}
                        apiRef={null}
                        loading={loadingEmployeeBandHistory}
                        pagination={null}
                    />
                </div>
                <div>
                    <h3 className="font-bold text-base mb-5">Employee Notch</h3>
                    <CustomStyledTable
                        rows={!activeEmployee?.id ? [] : (employeeNotchHistory?.data || [])}
                        columns={EmployeeNotchHistoryColumns}
                        apiRef={null}
                        loading={loadingEmployeeNotchHistory}
                        pagination={null}
                    />
                </div>
                <div>
                    <h3 className="font-bold text-base mb-5">Qualification</h3>
                    <CustomStyledTable
                        rows={!activeEmployee?.id ? [] : (employeeQualifciationHistory?.data || [])}
                        columns={EmployeeQualificationHistoryColumns}
                        apiRef={null}
                        loading={loadingEmployeeQualificationHistory}
                        pagination={null}
                    />
                </div>
            </section>
		</>
	);
};

export default EmployeesHistory;