import {
    CompanyPaginator,
    CountPayrollCompanyDto,
    CreatePayrollCompanyDto,
    PayrollCompanyDto,
    QueryPayrollCompanyDto, UpdatePayrollCompanyDto
} from "../../../models/company-account.dto";
import {HttpClient} from "../requests";
import ENDPOINTS from "../endpoints";
import {QueryOptionsDto} from "../../../models/generic.dto";
import {
    CountEmployeeDto,
    CreateEmployeeDto,
    CreateEmployeePayInfoDto,
    EmployeeDto,
    EmployeePaginator,
    EmployeeTerminationDto,
    SearchEmployeeDto,
    UpdateEmployeeDto, UpdateEmployeePayInfoDto, UploadEmployeeResponse
} from "../../../models/employee.dto";
import {
    CostCenterDto,
    CostCenterPaginator,
    CreateCostCenterDto,
    QueryCostCentreDto,
    SearchCostCentreDto,
    UpdateCostCenterDto
} from "../../../models/cost-center.dto";
import {
    CompanyGroupingDto,
    CompanyGroupingPaginator,
    CreateCompanyGroupingDto, QueryCompanyGroupingDto, UpdateCompanyGroupingDto
} from "../../../models/company-grouping.dto";
import {CreatePayPeriodDto, PayPeriodDto, PayPeriodPaginator, QueryPayPeriodDto, SearchPayPeriodDto, UpdatePayPeriodDto} from "../../../models/pay-period.dto";
import {CreateNotchDto, NotchDto, NotchPaginator, QueryNotchDto, UpdateNotchDto} from "../../../models/notches.dto";
import {
    CreateGradeLevelDto,
    GradeLevelDto,
    GradeLevelPaginator,
    UpdateGradeLevelDto,
    QueryGradeLevelDto,
    SearchGradeLevelDto
} from "../../../models/grade-level.dto";
import {CreateTaxDto, TaxDto, TaxPaginator, UpdateTaxDto} from "../../../models/tax.dto";
import {CreateHolidayDto, HolidayDto, HolidayPaginator, QueryHolidayDto, SearchHolidayDto, UpdateHolidayDto} from "../../../models/holiday.dto";
import {
    AssetTypeDto,
    AssetTypePaginator,
    CreateAssetTypeDto,
    QueryAssetTypeDto,
    UpdateAssetTypeDto
} from "../../../models/assets-type.dto";
import {BonusDto, BonusPaginator, CreateBonusDto, SearchBonusDto, UpdateBonusDto} from "../../../models/bonus.dto";
import {AllowanceDto, AllowancePaginator, CreateAllowanceDto, SearchAllownaceDto, UpdateAllowanceDto} from "../../../models/allowance.dto";
import {
    CreateSavingsSchemeDto,
    EmployeeSavingsSchemeDto,
    QuerySavingsSchemeDto,
    SavingsSchemeDto,
    SavingsSchemePaginator,
    SearchSavingsSchemeDto,
    UpdateSavingsSchemeDto
} from "../../../models/savings-scheme.dto";
import {CreatePensionDto, PensionDto, PensionPaginator, SearchPensionDto, UpdatePensionDto} from "../../../models/pension.dto";
import {CreateUnionDto, QueryUnionDto, UnionDto, UnionPaginator, UpdateUnionDto} from "../../../models/union.dto";
import {
    AssignedAssetDto,
    AssignedAssetPaginator,
    CreateAssignedAssetDto,
    QueryAssignedAssetDto, UpdateAssignedAssetDto
} from "../../../models/asset-assign.dto";
import {
    EmployeePayInfoDto,
    EmployeePayInfoPaginator,
    QueryEmployeePayInfoDto
} from "../../../models/employee-pay-info.dto";
import {
    CreateEmployeeQualificationDto,
    EmployeeQualificationDto,
    EmployeeQualificationPaginator,
    QueryEmployeeQualificationDto, UpdateEmployeeQualificationDto
} from "../../../models/employee-qualification.dto";
import {
    CreateEmployeeRelativeDto,
    EmployeeRelativeDto,
    EmployeeRelativePaginator,
    QueryEmployeeRelativeDto, UpdateEmployeeRelativeDto
} from "../../../models/employee-relative.dto";
import {
    CreateEmployeeUnionDto,
    EmployeeUnionDto,
    EmployeeUnionPaginator,
    QueryEmployeeUnionDto, UpdateEmployeeUnionDto
} from "../../../models/employee-union.dto";
import {
    ContractTypeDto,
    ContractTypePaginator,
    CreateContractTypeDto,
    QueryContractTypeDto, UpdateContractTypeDto
} from "../../../models/contract-type.dto";
import {
    CreateDisabilitiesDto,
    DisabilitiesDto,
    DisabilitiesPaginator,
    QueryDisabilitiesDto, UpdateDisabilitiesDto
} from "../../../models/disabilities.dto";
import {
    CreateNationalityDto,
    NationalityDto,
    NationalityPaginator,
    QueryNationalityDto, SearchNationalityDto, UpdateNationalityDto
} from "../../../models/nationality.dto";
import {
    CreateWorkDaysInAPeriodDto,
    QueryWorkDaysInAPeriodDto, UpdateWorkDaysInAPeriodDto,
    WorkDaysInAPeriodDto,
    WorkDaysInAPeriodPaginator
} from "../../../models/work-days-in-a-period.dto";
import {
    CreateEmployeeBandDto,
    EmployeeBandDto,
    EmployeeBandPaginator,
    QueryEmployeeBandDto, SearchEmployeeBandDto, UpdateEmployeeBandDto
} from "../../../models/employee-band.dto";
import {
    CreateCustomTaxReliefDto,
    CustomTaxReliefDto,
    CustomTaxReliefPaginator,
    QueryCustomTaxReliefDto, UpdateCustomTaxReliefDto
} from "../../../models/custom-tax-relief.dto";
import {
    CreateJobTitleDto,
    JobTitleDto,
    JobTitlePaginator,
    QueryJobTitleDto,
    UpdateJobTitleDto
} from "../../../models/job-titles.dto";
import {
    BenefitDto,
    BenefitPaginator,
    CreateBenefitDto,
    QueryBenefitDto,
    SearchBenefitDto,
    UpdateBenefitDto
} from "../../../models/benefit.dto";
import {
    CreateEmployeeExplicitDeductionDto,
    EmployeeExplicitDeductionDto,
    EmployeeExplicitDeductionPaginator,
    QueryEmployeeExplicitDeductionDto, UpdateEmployeeExplicitDeductionStatusDto
} from "../../../models/employee-explicit-deduction.dto";
import {
    CreateOvertimeDto,
    OvertimeDto,
    OvertimePaginator,
    QueryOvertimeDto,
    SearchOvertimeDto,
    UpdateOvertimeDto
} from "../../../models/overtime.dto";
import {
    CreateOvertimePaymentTierDto,
    OvertimePaymentTierDto,
    OvertimePaymentTierPaginator,
    QueryOvertimePaymentTierDto, UpdateOvertimePaymentTierDto
} from "../../../models/overtime-payment-tier.dto";
import {
    CompanyCurrencyConfigDto,
    CompanyCurrencyConfigPaginator, CreateCompanyCurrencyConfigDto,
    QueryCompanyCurrencyConfigDto, UpdateCompanyCurrencyConfigDto
} from "../../../models/company-currency.dto";
import {
    CreateExplicitDeductionDto,
    EmployeeBandExplicitDeductionDto,
    ExplicitDeductionDto,
    ExplicitDeductionPaginator,
    QueryExplicitDeductionDto, SearchExplicitDeductionDto, UpdateExplicitDeductionDto
} from "../../../models/explicit-deduction.dto";
import {
    CreateEmployeeBandAllowanceDto,
    EmployeeBandAllowanceDto,
    EmployeeBandAllowancePaginator,
    QueryEmployeeBandAllowanceDto, UpdateEmployeeBandAllowanceDto
} from "../../../models/employee-band-allowance.dto";
import {
    CreateEmployeeBandBenefitDto,
    EmployeeBandBenefitDto,
    EmployeeBandBenefitPaginator,
    QueryEmployeeBandBenefitDto, UpdateEmployeeBandBenefitStatusDto
} from "../../../models/employee-band-benefit.dto";
import {
    CreateEmployeeBandBonusDto,
    EmployeeBandBonusDto,
    EmployeeBandBonusPaginator,
    QueryEmployeeBandBonusDto, UpdateEmployeeBandBonusDto
} from "../../../models/employee-band-bonus.dto";
import {
    CreateEmployeeBandLoanBenefitDto,
    EmployeeBandLoanBenefitDto,
    EmployeeBandLoanBenefitPaginator,
    QueryEmployeeBandLoanBenefitDto, UpdateEmployeeBandLoanBenefitStatusDto
} from "../../../models/employee-band-loan-benefit.dto";
import {
    CreateEmployeeBandSavingsSchemeDto,
    EmployeeBandSavingsSchemeDto,
    EmployeeBandSavingsSchemePaginator,
    QueryEmployeeBandSavingsSchemeDto, UpdateEmployeeBandSavingsSchemeStatusDto
} from "../../../models/employee-band-savings-scheme.dto";
import {
    BenefitTaxDto,
    BenefitTaxPaginator,
    CreateBenefitTaxDto, CreateIndustryBenefitTaxDto, QueryBenefitTaxDto,
    UpdateBenefitTaxDto
} from "../../../models/benefit-tax.dto";
import {
    CreateTaxRateDto,
    QueryTaxRateDto,
    TaxRate,
    TaxRateDto,
    TaxRatePaginator,
    UpdateTaxRateDto
} from "../../../models/tax-rate.dto";
import {
    CreateTaxReliefDto,
    QueryTaxReliefDto,
    TaxRelief,
    TaxReliefDto, TaxReliefPaginator,
    UpdateTaxReliefDto
} from "../../../models/tax-relief.dto";
import {
    CreateTaxReliefTypeDto,
    QueryTaxReliefTypeDto, SearchTaxReliefTypeDto,
    TaxReliefTypeDto,
    TaxReliefTypePaginator, UpdateTaxReliefTypeDto
} from "../../../models/tax-relief-type.dto";
import {
    BackPayDto,
    BackPayPaginator,
    CreateBackPayDto,
    QueryBackPayDto,
    SearchBackPayDto, UpdateBackPayDto
} from "../../../models/back-pay.dto";
import {
    BenefitTypeDto,
    BenefitTypePaginator,
    CreateBenefitTypeDto,
    QueryBenefitTypeDto, SearchBenefitTypeDto, UpdateBenefitTypeDto
} from "../../../models/benefit-type.dto";
import {
    CreateIndustryDto,
    IndustryDto,
    IndustryPaginator,
    QueryIndustryDto,
    SearchIndustryDto,
    UpdateIndustryDto
} from "../../../models/industry.dto";
import {
    IndustryBenefitTaxDto,
    IndustryBenefitTaxPaginator,
    QueryIndustryBenefitTaxDto,
    UpdateIndustryBenefitTaxDto
} from "../../../models/industry-benefit-tax.dto";
import {
    CreateTaxCodeDto,
    QueryTaxCodeDto, SearchTaxCodeDto, SetDefaultTaxCodeDto,
    TaxCodeDto,
    TaxCodePaginator,
    UpdateTaxCodeDto
} from "../../../models/tax-code.dto";
import { getAccessToken } from "../app.data";
import { CompanyLevelLeavePackageDto, CompanyLevelLeavePackagePaginator, CreateCompanyLevelLeavePackageDto, QueryCompanyLevelLeavePackageDto } from "../../../models/company-level-leave-package.dto";
import { CompanyLevelDto, CompanyLevelPaginator, CreateCompanyLevelDto, QueryCompanyLevelDto, UpdateCompanyLevelDto } from "../../../models/company-level.dto";
import { CreateEmployeeAccountInfoDto, EmployeeAccountInfoDto, EmployeeAccountInfoPaginator, QueryEmployeeAccountInfoDto, UpdateEmployeeAccountInfoDto } from "../../../models/employee-account-info.dto";
import { CreateEmployeeBandPensionDto, EmployeeBandPensionDto, EmployeeBandPensionPaginator, QueryEmployeeBandPensionDto, UpdateEmployeeBandPensionStatusDto } from "../../../models/employee-band-pension.dto";
import { CreateEmployeeBandCustomTaxReliefDto, EmployeeBandCustomTaxReliefDto, EmployeeBandCustomTaxReliefPaginator, QueryEmployeeBandCustomTaxReliefDto, UpdateEmployeeBandCustomTaxReliefDto, UpdateEmployeeBandCustomTaxReliefStatusDto } from "../../../models/employee-band-custom-tax-relief.dto";
import { CreateEmployeeBandExplicitDeductionDto, EmployeeBandExplicitDeductionPaginator, QueryEmployeeBandExplicitDeductionDto, UpdateEmployeeBandExplicitDeductionStatusDto } from "../../../models/employee-band-explicit-deduction.dto";
import { CreateEmployeeBenefitDto, EmployeeBenefitDto, EmployeeBenefitPaginator, QueryEmployeeBenefitDto, UpdateEmployeeBenefitStatusDto } from "../../../models/employee-benefit.dto";
import { CreateEmployeeContractDto, EmployeeContractDto, EmployeeContractPaginator, QueryEmployeeContractDto, UpdateEmployeeContractDto } from "../../../models/employee-contract.dto";
import { CreateEmployeeCustomTaxReliefDto, EmployeeCustomTaxReliefDto, EmployeeCustomTaxReliefPaginator, QueryEmployeeCustomTaxReliefDto, UpdateEmployeeCustomTaxReliefDto } from "../../../models/employee-custom-tax-relief.dto";
import { CreateEmployeeLoanBenefitDto, EmployeeLoanBenefitDto, EmployeeLoanBenefitPaginator, QueryEmployeeLoanBenefitDto, UpdateEmployeeLoanBenefitStatusDto } from "../../../models/employee-loan-benefit.dto";
import { CreateEmployeeSavingsSchemeDto, EmployeeSavingsSchemePaginator, QueryEmployeeSavingsSchemeDto, UpdateEmployeeSavingsSchemeStatusDto } from "../../../models/employee-savings-scheme.dto";
import { CreateErpIntegrationMappingDto, ErpIntegrationMappingDto, ErpIntegrationMappingPaginator, QueryErpIntegrationMappingDto, SearchErpIntegrationMappingDto, UpdateErpIntegrationMappingDto } from "../../../models/erp-integration-mapping.dto";
import { CreateLoanBenefitDto, LoanBenefitDto, LoanBenefitPaginator, QueryLoanBenefitDto, SearchLoanBenefitDto, UpdateLoanBenefitDto } from "../../../models/loan-benefit.dto";
import { CreateLoanDto, LoanDto, LoanPaginator, QueryLoanDto, UpdateLoanDto } from "../../../models/loan.dto";
import { CreatePartialSalaryDto, PartialSalaryDto, PartialSalaryPaginator, QueryPartialSalaryDto, QueryPartialSalaryGivenPayPeriodDto, UpdatePartialSalaryDto } from "../../../models/partial-salary.dto";
import { CreateSalaryAdvanceDto, QuerySalaryAdvanceDto, SalaryAdvanceDto, SalaryAdvancePaginator, UpdateSalaryAdvanceDto } from "../../../models/salary-advance.dto";
import { CreateDepartmentDto, DepartmentDto, DepartmentPaginator, QueryDepartmentDto, SearchDepartmentDto, UpdateDepartmentDto } from "../../../models/department.dto";
import { CreateDepartmentLeadershipDto, DepartmentLeadershipDto, DepartmentLeadershipPaginator, QueryDepartmentLeadershipDto, UpdateDepartmentLeadershipDto } from "../../../models/department-leadership.dto";
import { EmployeeQualificationHistoryPaginator, QueryEmployeeQualificationHistoryDto } from "../../../models/employee-qualification-history.dto";
import { EmployeeJobTitleHistoryPaginator, QueryEmployeeJobTitleHistoryDto } from "../../../models/employee-job-title-history.dto";
import { EmployeeNotchHistoryPaginator, QueryEmployeeNotchHistoryDto } from "../../../models/employee-notch-history.dto";
import { EmployeeBandHistoryPaginator, QueryEmployeeBandHistoryDto } from "../../../models/employee-band-history.dto";
import { EmployeeBasicSalaryHistoryPaginator, QueryEmployeeBasicSalaryHistoryDto } from "../../../models/employee-basic-pay-history.dto";
import { EmployeeEngagementPaginator, QueryEmployeeEngagementDto } from "../../../models/employee-engagement.dto";
import { EmployeeDisengagementPaginator, QueryEmployeeDisengagementDto } from "../../../models/employee-disengagement.dto";

export class ProcessingCompany {
    getCompany = (query: QueryPayrollCompanyDto) => HttpClient
        .get<CompanyPaginator>(ENDPOINTS.PROCESSING_COMPANY.COMPANY.GET, query);
    createCompany = (payload: CreatePayrollCompanyDto) => HttpClient
        .post<{ data: PayrollCompanyDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY.CREATE, payload);
    getCompanyById = (companyId: number) => HttpClient
        .get<{ data: PayrollCompanyDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY.DETAIL.replace('{companyId}', String(companyId)));
    getCompanyCount = (query?: CountPayrollCompanyDto) => HttpClient
        .get<{ data: { count: number} }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY.GET_COUNT, query);
    updateCompany = (companyId: number, payload: UpdatePayrollCompanyDto) => HttpClient
        .patch<{ data: PayrollCompanyDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY.UPDATE.replace('{companyId}', String(companyId)), payload);
    deleteCompany = (companyId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.COMPANY.UPDATE.replace('{companyId}', String(companyId)));

    getEmployee = (query: QueryOptionsDto) => HttpClient
        .get<EmployeePaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.GET, query);
    getEmployeeSearch = (query?: SearchEmployeeDto) => HttpClient
        .get<EmployeePaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.SEARCH, query);
    getEmployeeById = (employeeId: number) => HttpClient
        .get<{ data: EmployeeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.DETAIL.replace('{employeeId}', String(employeeId)));
    getEmployeeCount = (query?: CountEmployeeDto) => HttpClient
        .get<{ data: { count: number} }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.GET_COUNT, query);
    createEmployee = (payload: CreateEmployeeDto) => HttpClient
        .post<{ data: EmployeeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.CREATE, payload);
    updateEmployee = (employeeId: number, payload: UpdateEmployeeDto) => HttpClient
        .patch<{ data: EmployeeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.UPDATE.replace('{employeeId}', String(employeeId)), payload);
    updloadEmployee = (companyId: number, payload: FormData) => HttpClient
        .postFile<UploadEmployeeResponse>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.UPLOAD.replace('{companyId}', String(companyId)), payload);
    deleteEmployee = (employeeId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.DELETE.replace('{employeeId}', String(employeeId)));
    terminateEmployee = (employeeId: number, terminationData: EmployeeTerminationDto) => HttpClient
        .put<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE.TERMINATE.replace('{employeeId}', String(employeeId)), terminationData);

    getCostCenter = (query?: QueryCostCentreDto) => HttpClient
        .get<CostCenterPaginator>(ENDPOINTS.PROCESSING_COMPANY.COST_CENTER.GET, query);
    getCostCenterSearch = (query?: SearchCostCentreDto) => HttpClient
        .get<CostCenterPaginator>(ENDPOINTS.PROCESSING_COMPANY.COST_CENTER.SEARCH, query);
    getCostCenterById = (costCenterId: number) => HttpClient
        .get<{ data: CostCenterDto }>(ENDPOINTS.PROCESSING_COMPANY.COST_CENTER.DETAIL.replace('{costCenterId}', String(costCenterId)));
    createCostCenter = (payload: CreateCostCenterDto) => HttpClient
        .post<{ data: CostCenterDto }>(ENDPOINTS.PROCESSING_COMPANY.COST_CENTER.CREATE, payload);
    updateCostCenter = (costCenterId: number, payload: UpdateCostCenterDto) => HttpClient
        .patch<{ data: CostCenterDto }>(ENDPOINTS.PROCESSING_COMPANY.COST_CENTER.UPDATE
            .replace('{costCenterId}', String(costCenterId)), payload);
    deleteCostCenterById = (costCenterId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.COST_CENTER.DELETE
            .replace('{costCenterId}', String(costCenterId)));

    getCompanyGrouping = (query?: QueryCompanyGroupingDto) => HttpClient
        .get<CompanyGroupingPaginator>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_GROUPING.GET, query);
    getCompanyGroupingByTypeAndCompanyId = (companyId: number, type: string) => HttpClient
        .get<CompanyGroupingDto[]>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_GROUPING.BY_TYPE
            .replace('{companyId}', String(companyId))
            .replace('{type}', (type))
        );
    getCompanyGroupingById = (groupingId: number) => HttpClient
        .get<{ data: CompanyGroupingDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_GROUPING.DETAIL.replace('{groupingId}', String(groupingId)));
    createCompanyGrouping = (payload: CreateCompanyGroupingDto) => HttpClient
        .post<{ data: CompanyGroupingDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_GROUPING.CREATE, payload);
    updateCompanyGrouping = (groupingId: number, payload: UpdateCompanyGroupingDto) => HttpClient
        .patch<{ data: CompanyGroupingDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_GROUPING.UPDATE
            .replace('{groupingId}', String(groupingId)), payload);
    deleteCompanyGroupingById = (groupingId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_GROUPING.DELETE
            .replace('{groupingId}', String(groupingId)));


    getPayPeriod = (query?: QueryPayPeriodDto) => HttpClient
        .get<PayPeriodPaginator>(ENDPOINTS.PROCESSING_COMPANY.PAY_PERIOD.GET, query);
    getPayPeriodSearch = (query?: SearchPayPeriodDto) => HttpClient
        .get<PayPeriodPaginator>(ENDPOINTS.PROCESSING_COMPANY.PAY_PERIOD.SEARCH, query);
    getPayPeriodById = (payPeriodId: number) => HttpClient
        .get<{ data: PayPeriodDto }>(ENDPOINTS.PROCESSING_COMPANY.PAY_PERIOD.DETAIL.replace('{payPeriodId}', String(payPeriodId)));
    createPayPeriod = (payload: CreatePayPeriodDto) => HttpClient
        .post<{ data:PayPeriodDto }>(ENDPOINTS.PROCESSING_COMPANY.PAY_PERIOD.CREATE, payload);
    updatePayPeriod = (payPeriodId: number, payload: UpdatePayPeriodDto) => HttpClient
        .patch<{ data:PayPeriodDto }>(ENDPOINTS.PROCESSING_COMPANY.PAY_PERIOD.UPDATE
            .replace('{payPeriodId}', String(payPeriodId)), payload);
    deletePayPeriod = (payPeriodId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.PAY_PERIOD.DELETE
            .replace('{payPeriodId}', String(payPeriodId)));

    getNotch = (query?: QueryNotchDto) => HttpClient
        .get<NotchPaginator>(ENDPOINTS.PROCESSING_COMPANY.NOTCHES.GET, query);
    getNotchById = (notchId: number) => HttpClient
        .get<{ data:NotchDto }>(ENDPOINTS.PROCESSING_COMPANY.NOTCHES.DETAIL.replace('{notchId}', String(notchId)));
    createNotch = (payload: CreateNotchDto) => HttpClient
        .post<{ data:NotchDto }>(ENDPOINTS.PROCESSING_COMPANY.NOTCHES.CREATE, payload);
    updateNotch = (notchId: number, payload: UpdateNotchDto) => HttpClient
        .patch<{ data:NotchDto }>(ENDPOINTS.PROCESSING_COMPANY.NOTCHES.UPDATE
            .replace('{notchId}', String(notchId)), payload);
    deleteNotch = (notchId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.NOTCHES.DELETE
            .replace('{notchId}', String(notchId)));

    getGradeLevel = (query?: QueryGradeLevelDto) => HttpClient
        .get<GradeLevelPaginator>(ENDPOINTS.PROCESSING_COMPANY.GRADE_LEVEL.GET, query);
    getGradeLevelSearch = (query?: SearchGradeLevelDto) => HttpClient
        .get<GradeLevelPaginator>(ENDPOINTS.PROCESSING_COMPANY.GRADE_LEVEL.SEARCH, query);
    getGradeLevelById = (gradeLevelId: number) => HttpClient
        .get<{ data:GradeLevelDto }>(ENDPOINTS.PROCESSING_COMPANY.GRADE_LEVEL.DETAIL
            .replace('{gradeLevelId}', String(gradeLevelId)));
    createGradeLevel = (payload: CreateGradeLevelDto) => HttpClient
        .post<{ data:GradeLevelDto }>(ENDPOINTS.PROCESSING_COMPANY.GRADE_LEVEL.CREATE, payload);
    updateGradeLevel = (gradeLevelId: number, payload: UpdateGradeLevelDto) => HttpClient
        .patch<{ data:GradeLevelDto }>(ENDPOINTS.PROCESSING_COMPANY.GRADE_LEVEL.UPDATE
            .replace('{gradeLevelId}', String(gradeLevelId)), payload);
    deleteGradeLevel = (gradeLevelId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.GRADE_LEVEL.DELETE
            .replace('{gradeLevelId}', String(gradeLevelId)));

    getTax = (query?: QueryOptionsDto) => HttpClient
        .get<TaxPaginator>(ENDPOINTS.PROCESSING_COMPANY.TAX.GET, query);
    getTaxById = (taxId: number) => HttpClient
        .get<TaxDto>(ENDPOINTS.PROCESSING_COMPANY.TAX.DETAIL
            .replace('{taxId}', String(taxId)));
    createTax = (payload: CreateTaxDto) => HttpClient
        .post<TaxDto>(ENDPOINTS.PROCESSING_COMPANY.TAX.CREATE, payload);
    updateTax = (taxId: number, payload: UpdateTaxDto) => HttpClient
        .patch<TaxDto>(ENDPOINTS.PROCESSING_COMPANY.TAX.UPDATE
            .replace('{taxId}', String(taxId)), payload);

    getHoliday = (query?: QueryHolidayDto) => HttpClient
        .get<HolidayPaginator>(ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.GET, query);
    getHolidaySearch = (query?: SearchHolidayDto) => HttpClient
        .get<HolidayPaginator>(ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.SEARCH, query);
    getHolidayById = (holidayId: number) => HttpClient
        .get<{ data:HolidayDto }>(ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.DETAIL
            .replace('{holidayId}', String(holidayId)));
    getHolidayByCode = (code: string) => HttpClient
        .get<{ data:HolidayDto }>(ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.GETBYCODE
                .replace('{code}', String(code)));
    createHoliday = (payload: CreateHolidayDto) => HttpClient
        .post<{ data:HolidayDto }>(ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.CREATE, payload);
    updateHoliday = (holidayId: number, payload: UpdateHolidayDto) => HttpClient
        .patch<{ data:HolidayDto }>(ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.UPDATE
            .replace('{holidayId}', String(holidayId)), payload);
    deleteHoliday = (holidayId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.HOLIDAY.DELETE
            .replace('{holidayId}', String(holidayId)));

    getAssetsType = (query?: QueryAssetTypeDto) => HttpClient
        .get<AssetTypePaginator>(ENDPOINTS.PROCESSING_COMPANY.ASSETS_TYPE.GET, query);
    getAssetsTypeById = (assetId: number) => HttpClient
        .get<{ data: AssetTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.ASSETS_TYPE.DETAIL
            .replace('{assetId}', String(assetId)));
    createAssetsType = (payload: CreateAssetTypeDto) => HttpClient
        .post<{ data: AssetTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.ASSETS_TYPE.CREATE, payload);
    updateAssetsType = (assetId: number, payload: UpdateAssetTypeDto) => HttpClient
        .patch<{ data: AssetTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.ASSETS_TYPE.UPDATE
            .replace('{assetId}', String(assetId)), payload);
    deleteAssetsTypeById = (assetId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.ASSETS_TYPE.DELETE
            .replace('{assetId}', String(assetId)));

    getBonus = (query?: QueryOptionsDto) => HttpClient
        .get<BonusPaginator>(ENDPOINTS.PROCESSING_COMPANY.BONUS.GET, query);
    getBonusSearch = (query?: SearchBonusDto) => HttpClient
        .get<BonusPaginator>(ENDPOINTS.PROCESSING_COMPANY.BONUS.SEARCH, query);
    getBonusById = (bonusId: number) => HttpClient
        .get<{ data:BonusDto }>(ENDPOINTS.PROCESSING_COMPANY.BONUS.DETAIL
            .replace('{bonusId}', String(bonusId)));
    createBonus = (payload: CreateBonusDto) => HttpClient
        .post<{ data:BonusDto }>(ENDPOINTS.PROCESSING_COMPANY.BONUS.CREATE, payload);
    updateBonus = (bonusId: number, payload: UpdateBonusDto) => HttpClient
        .patch<{ data:BonusDto }>(ENDPOINTS.PROCESSING_COMPANY.BONUS.UPDATE
            .replace('{bonusId}', String(bonusId)), payload);
    deleteBonusById = (bonusId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.BONUS.DELETE
            .replace('{bonusId}', String(bonusId)));

    getAllowance = (query?: QueryOptionsDto) => HttpClient
        .get<AllowancePaginator>(ENDPOINTS.PROCESSING_COMPANY.ALLOWANCE.GET, query);
    getAllowanceSearch = (query?: SearchAllownaceDto) => HttpClient
        .get<AllowancePaginator>(ENDPOINTS.PROCESSING_COMPANY.ALLOWANCE.SEARCH, query);
    getAllowanceById = (allowanceId: number) => HttpClient
        .get<{ data:AllowanceDto }>(ENDPOINTS.PROCESSING_COMPANY.ALLOWANCE.DETAIL
            .replace('{allowanceId}', String(allowanceId)));
    createAllowance = (payload: CreateAllowanceDto) => HttpClient
        .post<{ data:AllowanceDto }>(ENDPOINTS.PROCESSING_COMPANY.ALLOWANCE.CREATE, payload);
    updateAllowance = (allowanceId: number, payload: UpdateAllowanceDto) => HttpClient
        .patch<{ data:AllowanceDto }>(ENDPOINTS.PROCESSING_COMPANY.ALLOWANCE.UPDATE
            .replace('{allowanceId}', String(allowanceId)), payload);
    deleteAllowance = (allowanceId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.ALLOWANCE.DELETE
            .replace('{allowanceId}', String(allowanceId)));

    getSavingsScheme = (query?: QuerySavingsSchemeDto) => HttpClient
        .get<SavingsSchemePaginator>(ENDPOINTS.PROCESSING_COMPANY.SAVINGS_SCHEME.GET, query);
    getSavingsSchemeSearch = (query?: SearchSavingsSchemeDto) => HttpClient
        .get<SavingsSchemePaginator>(ENDPOINTS.PROCESSING_COMPANY.SAVINGS_SCHEME.SEARCH, query);
    getSavingsSchemeById = (savingsSchemeId: number) => HttpClient
        .get<{ data:SavingsSchemeDto }>(ENDPOINTS.PROCESSING_COMPANY.SAVINGS_SCHEME.DETAIL
            .replace('{savingsSchemeId}', String(savingsSchemeId)));
    createSavingsScheme = (payload: CreateSavingsSchemeDto) => HttpClient
        .post<{ data:SavingsSchemeDto }>(ENDPOINTS.PROCESSING_COMPANY.SAVINGS_SCHEME.CREATE, payload);
    updateSavingsScheme = (savingsSchemeId: number, payload: UpdateSavingsSchemeDto) => HttpClient
        .patch<{ data:SavingsSchemeDto }>(ENDPOINTS.PROCESSING_COMPANY.SAVINGS_SCHEME.UPDATE
            .replace('{savingsSchemeId}', String(savingsSchemeId)), payload);
    deleteSavingsScheme = (savingsSchemeId: number) => HttpClient
        .delete<{ data: SavingsSchemeDto }>(ENDPOINTS.PROCESSING_COMPANY.SAVINGS_SCHEME.DELETE
            .replace('{savingsSchemeId}', String(savingsSchemeId)));

    getPension = (query?: QueryOptionsDto) => HttpClient
        .get<PensionPaginator>(ENDPOINTS.PROCESSING_COMPANY.PENSION.GET, query);
    getPensionSearch = (query?: SearchPensionDto) => HttpClient
        .get<PensionPaginator>(ENDPOINTS.PROCESSING_COMPANY.PENSION.SEARCH, query);
    getPensionById = (pensionId: number) => HttpClient
        .get<{ data:PensionDto }>(ENDPOINTS.PROCESSING_COMPANY.PENSION.DETAIL
            .replace('{pensionId}', String(pensionId)));
    createPension = (payload: CreatePensionDto) => HttpClient
        .post<{ data:PensionDto }>(ENDPOINTS.PROCESSING_COMPANY.PENSION.CREATE, payload);
    updatePension = (pensionId: number, payload: UpdatePensionDto) => HttpClient
        .patch<{ data:PensionDto }>(ENDPOINTS.PROCESSING_COMPANY.PENSION.UPDATE
            .replace('{pensionId}', String(pensionId)), payload);
    deletePension = (pensionId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.PENSION.DELETE
            .replace('{pensionId}', String(pensionId)));

    getUnion = (query?: QueryUnionDto) => HttpClient
        .get<UnionPaginator>(ENDPOINTS.PROCESSING_COMPANY.UNION.GET, query);
    getUnionById = (unionId: number) => HttpClient
        .get<{ data:UnionDto }>(ENDPOINTS.PROCESSING_COMPANY.UNION.DETAIL
            .replace('{unionId}', String(unionId)));
    createUnion = (payload: CreateUnionDto) => HttpClient
        .post<{ data:UnionDto }>(ENDPOINTS.PROCESSING_COMPANY.UNION.CREATE, payload);
    updateUnion = (unionId: number, payload: UpdateUnionDto) => HttpClient
        .patch<{ data:UnionDto }>(ENDPOINTS.PROCESSING_COMPANY.UNION.UPDATE
            .replace('{unionId}', String(unionId)), payload);
    deleteUnion = (unionId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.UNION.DELETE
            .replace('{unionId}', String(unionId)));

    getAssignedAsset = (query?: QueryAssignedAssetDto) => HttpClient
        .get<AssignedAssetPaginator>(ENDPOINTS.PROCESSING_COMPANY.ASSIGN_ASSET.GET, query);
    getAssignedAssetById = (assignedAssetId: number) => HttpClient
        .get<{ data:AssignedAssetDto }>(ENDPOINTS.PROCESSING_COMPANY.ASSIGN_ASSET.DETAIL
            .replace('{assignedAssetId}', String(assignedAssetId)));
    createAssignedAsset = (payload: CreateAssignedAssetDto) => HttpClient
        .post<{ data:AssignedAssetDto }>(ENDPOINTS.PROCESSING_COMPANY.ASSIGN_ASSET.CREATE, payload);
    updateAssignedAsset = (assignedAssetId: number, payload: UpdateAssignedAssetDto) => HttpClient
        .patch<{ data:AssignedAssetDto }>(ENDPOINTS.PROCESSING_COMPANY.ASSIGN_ASSET.UPDATE
            .replace('{assignedAssetId}', String(assignedAssetId)), payload);
    deleteAssignedAssetById = (assignedAssetId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.ASSIGN_ASSET.DELETE
            .replace('{assignedAssetId}', String(assignedAssetId)));

    getEmployeePayInfo = (query?: QueryEmployeePayInfoDto) => HttpClient
        .get<EmployeePayInfoPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_PAY_INFO.GET, query);
    getEmployeePayInfoById = (payInfoId: number) => HttpClient
        .get<{ data:EmployeePayInfoDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_PAY_INFO.DETAIL
            .replace('{payInfoId}', String(payInfoId)));
    createEmployeePayInfo = (payload: CreateEmployeePayInfoDto) => HttpClient
        .post<{ data:EmployeePayInfoDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_PAY_INFO.CREATE, payload);
    updateEmployeePayInfo = (payInfoId: number, payload: UpdateEmployeePayInfoDto) => HttpClient
        .patch<{ data:EmployeePayInfoDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_PAY_INFO.UPDATE
            .replace('{payInfoId}', String(payInfoId)), payload);
    deleteEmployeePayInfo = (payInfoId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_PAY_INFO.DELETE
            .replace('{payInfoId}', String(payInfoId)));

    getEmployeeQualification = (query?: QueryEmployeeQualificationDto) => HttpClient
        .get<EmployeeQualificationPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_QUALIFICATION.GET, query);
    getEmployeeQualificationById = (qualificationId: number) => HttpClient
        .get<{ data:EmployeeQualificationDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_QUALIFICATION.DETAIL
            .replace('{qualificationId}', String(qualificationId)));
    createEmployeeQualification = (payload: CreateEmployeeQualificationDto) => HttpClient
        .post<{ data:EmployeeQualificationDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_QUALIFICATION.CREATE, payload);
    updateEmployeeQualification = (qualificationId: number, payload: UpdateEmployeeQualificationDto) => HttpClient
        .patch<{ data:EmployeeQualificationDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_QUALIFICATION.UPDATE
            .replace('{qualificationId}', String(qualificationId)), payload);
    deleteEmployeeQualification = (qualificationId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_QUALIFICATION.DELETE
            .replace('{qualificationId}', String(qualificationId)));

    getEmployeeRelative = (query?: QueryEmployeeRelativeDto) => HttpClient
        .get<EmployeeRelativePaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_RELATIVE.GET, query);
    getEmployeeRelativeById = (relativeId: number) => HttpClient
        .get<{ data:EmployeeRelativeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_RELATIVE.DETAIL
            .replace('{relativeId}', String(relativeId)));
    createEmployeeRelative = (payload: CreateEmployeeRelativeDto) => HttpClient
        .post<{ data:EmployeeRelativeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_RELATIVE.CREATE, payload);
    updateEmployeeRelative = (relativeId: number, payload: UpdateEmployeeRelativeDto) => HttpClient
        .patch<{ data:EmployeeRelativeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_RELATIVE.UPDATE
            .replace('{relativeId}', String(relativeId)), payload);
    deleteEmployeeRelative = (relativeId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_RELATIVE.DELETE
            .replace('{relativeId}', String(relativeId)));

    getEmployeeUnion = (query?: QueryEmployeeUnionDto) => HttpClient
        .get<EmployeeUnionPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_UNION.GET, query);
    getEmployeeUnionById = (employeeUnionId: number) => HttpClient
        .get<{ data:EmployeeUnionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_UNION.DETAIL
            .replace('{employeeUnionId}', String(employeeUnionId)));
    createEmployeeUnion = (payload: CreateEmployeeUnionDto) => HttpClient
        .post<{ data:EmployeeUnionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_UNION.CREATE, payload);
    updateEmployeeUnion = (employeeUnionId: number, payload: UpdateEmployeeUnionDto) => HttpClient
        .patch<{ data:EmployeeUnionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_UNION.UPDATE
            .replace('{employeeUnionId}', String(employeeUnionId)), payload);
    deleteEmployeeUnion = (employeeUnionId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_UNION.DELETE
            .replace('{employeeUnionId}', String(employeeUnionId)));

    getContractType = (query?: QueryContractTypeDto) => HttpClient
        .get<ContractTypePaginator>(ENDPOINTS.PROCESSING_COMPANY.CONTRACT_TYPE.GET, query);
    getContractTypeById = (contractTypeId: number) => HttpClient
        .get<{ data:ContractTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.CONTRACT_TYPE.DETAIL
            .replace('{contractTypeId}', String(contractTypeId)));
    createContractType = (payload: CreateContractTypeDto) => HttpClient
        .post<{ data:ContractTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.CONTRACT_TYPE.CREATE, payload);
    updateContractType = (contractTypeId: number, payload: UpdateContractTypeDto) => HttpClient
        .patch<{ data:ContractTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.CONTRACT_TYPE.UPDATE
            .replace('{contractTypeId}', String(contractTypeId)), payload);
    deletContractTypeById = (contractTypeId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.CONTRACT_TYPE.DELETE
            .replace('{contractTypeId}', String(contractTypeId)));

    getDisabilities = (query?: QueryDisabilitiesDto) => HttpClient
        .get<DisabilitiesPaginator>(ENDPOINTS.PROCESSING_COMPANY.DISABILITIES.GET, query);
    getDisabilitiesById = (disabilityId: number) => HttpClient
        .get<{ data:DisabilitiesDto }>(ENDPOINTS.PROCESSING_COMPANY.DISABILITIES.DETAIL
            .replace('{disabilityId}', String(disabilityId)));
    createDisabilities = (payload: CreateDisabilitiesDto) => HttpClient
        .post<{ data:DisabilitiesDto }>(ENDPOINTS.PROCESSING_COMPANY.DISABILITIES.CREATE, payload);
    updateDisabilities = (disabilityId: number, payload: UpdateDisabilitiesDto) => HttpClient
        .patch<{ data:DisabilitiesDto }>(ENDPOINTS.PROCESSING_COMPANY.DISABILITIES.UPDATE
            .replace('{disabilityId}', String(disabilityId)), payload);
    deleteDisabilities = (disabilityId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.DISABILITIES.DELETE
            .replace('{disabilityId}', String(disabilityId)));

    getNationality = (query?: QueryNationalityDto) => HttpClient
        .get<NationalityPaginator>(ENDPOINTS.PROCESSING_COMPANY.NATIONALITY.GET, query);
    getNationalitySearch = (query?: SearchNationalityDto) => HttpClient
        .get<NationalityPaginator>(ENDPOINTS.PROCESSING_COMPANY.NATIONALITY.SEARCH, query);
    getNationalityById = (nationalityId: number) => HttpClient
        .get<{ data:NationalityDto }>(ENDPOINTS.PROCESSING_COMPANY.NATIONALITY.DETAIL
            .replace('{nationalityId}', String(nationalityId)));
    createNationality = (payload: CreateNationalityDto) => HttpClient
        .post<{ data:NationalityDto }>(ENDPOINTS.PROCESSING_COMPANY.NATIONALITY.CREATE, payload);
    updateNationality = (nationalityId: number, payload: UpdateNationalityDto) => HttpClient
        .patch<{ data:NationalityDto }>(ENDPOINTS.PROCESSING_COMPANY.NATIONALITY.UPDATE
            .replace('{nationalityId}', String(nationalityId)), payload);
    deleteNationality = (nationalityId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.NATIONALITY.DELETE
            .replace('{nationalityId}', String(nationalityId)));


    getWorkDaysInAPeriod = (query?: QueryWorkDaysInAPeriodDto) => HttpClient
        .get<WorkDaysInAPeriodPaginator>(ENDPOINTS.PROCESSING_COMPANY.WORK_DAYS_IN_A_PERIOD.GET, query);
    getWorkDaysInAPeriodById = (workId: number) => HttpClient
        .get<{ data:WorkDaysInAPeriodDto }>(ENDPOINTS.PROCESSING_COMPANY.WORK_DAYS_IN_A_PERIOD.DETAIL
            .replace('{workId}', String(workId)));
    createWorkDaysInAPeriod = (payload: CreateWorkDaysInAPeriodDto) => HttpClient
        .post<{ data:WorkDaysInAPeriodDto }>(ENDPOINTS.PROCESSING_COMPANY.WORK_DAYS_IN_A_PERIOD.CREATE, payload);
    updateWorkDaysInAPeriod = (workId: number, payload: UpdateWorkDaysInAPeriodDto) => HttpClient
        .patch<{ data:WorkDaysInAPeriodDto }>(ENDPOINTS.PROCESSING_COMPANY.WORK_DAYS_IN_A_PERIOD.UPDATE
            .replace('{workId}', String(workId)), payload);
    deleteWorkDaysInAPeriod = (workId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.WORK_DAYS_IN_A_PERIOD.DELETE
            .replace('{workId}', String(workId)));

    getEmployeeBand = (query?: QueryEmployeeBandDto) => HttpClient
        .get<EmployeeBandPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND.GET, query);
    getEmployeeBandSearch = (query?: SearchEmployeeBandDto) => HttpClient
        .get<EmployeeBandPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND.SEARCH, query);
    getEmployeeBandById = (bandId: number) => HttpClient
        .get<{ data:EmployeeBandDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND.DETAIL
            .replace('{bandId}', String(bandId)));
    createEmployeeBand = (payload: CreateEmployeeBandDto) => HttpClient
        .post<{ data:EmployeeBandDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND.CREATE, payload);
    updateEmployeeBand = (bandId: number, payload: UpdateEmployeeBandDto) => HttpClient
        .patch<{ data:EmployeeBandDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND.UPDATE
            .replace('{bandId}', String(bandId)), payload);
    deleteEmployeeBand = (bandId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND.DELETE
            .replace('{bandId}', String(bandId)));

    getCustomTaxRelief = (query?: QueryCustomTaxReliefDto) => HttpClient
        .get<CustomTaxReliefPaginator>(ENDPOINTS.PROCESSING_COMPANY.CUSTOM_TAX_RELIEF.GET, query);
    getCustomTaxReliefById = (customTaxReliefId: number) => HttpClient
        .get<{ data:CustomTaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.CUSTOM_TAX_RELIEF.DETAIL
            .replace('{customTaxReliefId}', String(customTaxReliefId)));
    createCustomTaxRelief = (payload: CreateCustomTaxReliefDto) => HttpClient
        .post<{ data:CustomTaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.CUSTOM_TAX_RELIEF.CREATE, payload);
    updateCustomTaxRelief = (customTaxReliefId: number, payload: UpdateCustomTaxReliefDto) => HttpClient
        .patch<{ data:CustomTaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.CUSTOM_TAX_RELIEF.UPDATE
            .replace('{customTaxReliefId}', String(customTaxReliefId)), payload);
    deleteCustomTaxRelief = (customTaxReliefId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.CUSTOM_TAX_RELIEF.DELETE
            .replace('{customTaxReliefId}', String(customTaxReliefId)));

    getJobTitle = (query?: QueryJobTitleDto) => HttpClient
        .get<JobTitlePaginator>(ENDPOINTS.PROCESSING_COMPANY.JOB_TITLE.GET, query);
    getJobTitleById = (jobTitleId: number) => HttpClient
        .get<{ data:JobTitleDto }>(ENDPOINTS.PROCESSING_COMPANY.JOB_TITLE.DETAIL
            .replace('{jobTitleId}', String(jobTitleId)));
    createJobTitle = (payload: CreateJobTitleDto) => HttpClient
        .post<{ data:JobTitleDto }>(ENDPOINTS.PROCESSING_COMPANY.JOB_TITLE.CREATE, payload);
    updateJobTitle = (jobTitleId: number, payload: UpdateJobTitleDto) => HttpClient
        .patch<{ data:JobTitleDto }>(ENDPOINTS.PROCESSING_COMPANY.JOB_TITLE.UPDATE
            .replace('{jobTitleId}', String(jobTitleId)), payload);
    deleteJobTitle = (jobTitleId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.JOB_TITLE.DELETE
            .replace('{jobTitleId}', String(jobTitleId)));

    getBenefit = (query?: QueryBenefitDto) => HttpClient
        .get<BenefitPaginator>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT.GET, query);
    getBenefitSearch = (query?: SearchBenefitDto) => HttpClient
        .get<BenefitPaginator>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT.SEARCH, query);
    getBenefitById = (benefitId: number) => HttpClient
        .get<{ data:BenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT.DETAIL
            .replace('{benefitId}', String(benefitId)));
    createBenefit = (payload: CreateBenefitDto) => HttpClient
        .post<{ data:BenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT.CREATE, payload);
    updateBenefit = (benefitId: number, payload: UpdateBenefitDto) => HttpClient
        .patch<{ data:BenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT.UPDATE
            .replace('{benefitId}', String(benefitId)), payload);
    deleteBenefit = (benefitId: number) => HttpClient
        .delete<{ data: BenefitDto}>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT.DELETE
            .replace('{benefitId}', String(benefitId)));


    getEmployeeExplicitDeduction = (query?: QueryEmployeeExplicitDeductionDto) => HttpClient
        .get<EmployeeExplicitDeductionPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_EXPLICIT_DEDUCTION.GET, query);
    getEmployeeExplicitDeductionById = (explicitDeductionId: number) => HttpClient
        .get<{ data:EmployeeExplicitDeductionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_EXPLICIT_DEDUCTION.DETAIL
            .replace('{explicitDeductionId}', String(explicitDeductionId)));
    createEmployeeExplicitDeduction = (payload: CreateEmployeeExplicitDeductionDto) => HttpClient
        .post<{ data:EmployeeExplicitDeductionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_EXPLICIT_DEDUCTION.CREATE, payload);
    updateEmployeeExplicitDeductionStatus = (explicitDeductionId: number, payload: UpdateEmployeeExplicitDeductionStatusDto) => HttpClient
        .patch<{ data:EmployeeExplicitDeductionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_EXPLICIT_DEDUCTION.UPDATE
            .replace('{explicitDeductionId}', String(explicitDeductionId)), payload);
    deleteEmployeeExplicitDeductionStatus = (explicitDeductionId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_EXPLICIT_DEDUCTION.DELETE
            .replace('{explicitDeductionId}', String(explicitDeductionId)));

    getOvertime = (query?: QueryOvertimeDto) => HttpClient
        .get<OvertimePaginator>(ENDPOINTS.PROCESSING_COMPANY.OVERTIME.GET, query);
    getOvertimeSearch = (query?: SearchOvertimeDto) => HttpClient
        .get<OvertimePaginator>(ENDPOINTS.PROCESSING_COMPANY.OVERTIME.SEARCH, query);
    getOvertimeById = (overtimeId: number) => HttpClient
        .get<{ data:OvertimeDto }>(ENDPOINTS.PROCESSING_COMPANY.OVERTIME.DETAIL.replace('{overtimeId}', String(overtimeId)));
    createOvertime = (payload: CreateOvertimeDto) => HttpClient
        .post<{ data:OvertimeDto }>(ENDPOINTS.PROCESSING_COMPANY.OVERTIME.CREATE, payload);
    updateOvertime = (overtimeId: number, payload: UpdateOvertimeDto) => HttpClient
        .patch<{ data:OvertimeDto }>(ENDPOINTS.PROCESSING_COMPANY.OVERTIME.UPDATE
            .replace('{overtimeId}', String(overtimeId)), payload);
    deleteOvertime = (overtimeId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.OVERTIME.DELETE
            .replace('{overtimeId}', String(overtimeId)));

    getOvertimePaymentTier = (query?: QueryOvertimePaymentTierDto) => HttpClient
        .get<OvertimePaymentTierPaginator>(ENDPOINTS.PROCESSING_COMPANY.OVERTIME_PAYMENT_TIER.GET, query);
    getOvertimePaymentTierById = (paymentTierId: number) => HttpClient
        .get<{ data:OvertimePaymentTierDto }>(ENDPOINTS.PROCESSING_COMPANY.OVERTIME_PAYMENT_TIER.DETAIL.replace('{paymentTierId}', String(paymentTierId)));
    createOvertimePaymentTier = (payload: CreateOvertimePaymentTierDto) => HttpClient
        .post<{ data:OvertimePaymentTierDto }>(ENDPOINTS.PROCESSING_COMPANY.OVERTIME_PAYMENT_TIER.CREATE, payload);
    updateOvertimePaymentTier = (paymentTierId: number, payload: UpdateOvertimePaymentTierDto) => HttpClient
        .patch<{ data:OvertimePaymentTierDto }>(ENDPOINTS.PROCESSING_COMPANY.OVERTIME_PAYMENT_TIER.UPDATE
            .replace('{paymentTierId}', String(paymentTierId)), payload);
    deleteOvertimePaymentTier = (paymentTierId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.OVERTIME_PAYMENT_TIER.DELETE
            .replace('{paymentTierId}', String(paymentTierId)));

    getCompanyCurrencyConfig = (query?: QueryCompanyCurrencyConfigDto) => HttpClient
        .get<CompanyCurrencyConfigPaginator>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_CURRENCY.GET, query);
    getCompanyCurrencyConfigById = (currencyConfigId: number) => HttpClient
        .get<{ data:CompanyCurrencyConfigDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_CURRENCY.DETAIL.replace('{currencyConfigId}', String(currencyConfigId)));
    createCompanyCurrencyConfig = (payload: CreateCompanyCurrencyConfigDto) => HttpClient
        .post<{ data:CompanyCurrencyConfigDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_CURRENCY.CREATE, payload);
    updateCompanyCurrencyConfig = (currencyConfigId: number, payload: UpdateCompanyCurrencyConfigDto) => HttpClient
        .patch<{ data:CompanyCurrencyConfigDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_CURRENCY.UPDATE
            .replace('{currencyConfigId}', String(currencyConfigId)), payload);
    deleteCompanyCurrencyConfig = (currencyConfigId: number) => HttpClient
        .delete<{ data:CompanyCurrencyConfigDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_CURRENCY.UPDATE
            .replace('{currencyConfigId}', String(currencyConfigId)));

    getExplicitDeduction = (query?: QueryExplicitDeductionDto) => HttpClient
        .get<ExplicitDeductionPaginator>(ENDPOINTS.PROCESSING_COMPANY.EXPLICIT_DEDUCTION.GET, query);
    getExplicitDeductionSearch = (query?: SearchExplicitDeductionDto) => HttpClient
        .get<ExplicitDeductionPaginator>(ENDPOINTS.PROCESSING_COMPANY.EXPLICIT_DEDUCTION.SEARCH, query);
    getExplicitDeductionById = (explicitDeductionId: number) => HttpClient
        .get<{ data:ExplicitDeductionDto }>(ENDPOINTS.PROCESSING_COMPANY.EXPLICIT_DEDUCTION.DETAIL
            .replace('{explicitDeductionId}', String(explicitDeductionId)));
    createExplicitDeduction = (payload: CreateExplicitDeductionDto) => HttpClient
        .post<{ data:ExplicitDeductionDto }>(ENDPOINTS.PROCESSING_COMPANY.EXPLICIT_DEDUCTION.CREATE, payload);
    updateExplicitDeduction = (explicitDeductionId: number, payload: UpdateExplicitDeductionDto) => HttpClient
        .patch<{ data:ExplicitDeductionDto }>(ENDPOINTS.PROCESSING_COMPANY.EXPLICIT_DEDUCTION.UPDATE
            .replace('{explicitDeductionId}', String(explicitDeductionId)), payload);
    deleteExplicitDeduction = (explicitDeductionId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EXPLICIT_DEDUCTION.DELETE
            .replace('{explicitDeductionId}', String(explicitDeductionId)));

    getEmployeeBandAllowance = (query?: QueryEmployeeBandAllowanceDto) => HttpClient
        .get<EmployeeBandAllowancePaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_ALLOWANCE.GET, query);
    getEmployeeBandAllowanceById = (bandId: number) => HttpClient
        .get<{ data:EmployeeBandAllowanceDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_ALLOWANCE.DETAIL
            .replace('{bandId}', String(bandId)));
    createEmployeeBandAllowance = (payload: CreateEmployeeBandAllowanceDto) => HttpClient
        .post<{ data:EmployeeBandAllowanceDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_ALLOWANCE.CREATE, payload);
    updateEmployeeBandAllowance = (bandId: number, payload: UpdateEmployeeBandAllowanceDto) => HttpClient
        .patch<{ data:EmployeeBandAllowanceDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_ALLOWANCE.UPDATE
            .replace('{bandId}', String(bandId)), payload);
    deleteEmployeeBandAllowanceById = (bandId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_ALLOWANCE.DELETE
            .replace('{bandId}', String(bandId)));

    getEmployeeBandBenefit = (query?: QueryEmployeeBandBenefitDto) => HttpClient
        .get<EmployeeBandBenefitPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_BENEFIT.GET, query);
    getEmployeeBandBenefitById = (bandId: number) => HttpClient
        .get<{ data:EmployeeBandBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_BENEFIT.DETAIL
            .replace('{bandId}', String(bandId)));
    createEmployeeBandBenefit = (payload: CreateEmployeeBandBenefitDto) => HttpClient
        .post<{ data:EmployeeBandBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_BENEFIT.CREATE, payload);
    updateEmployeeBandBenefitStatus = (bandId: number, payload: UpdateEmployeeBandBenefitStatusDto) => HttpClient
        .patch<{ data:EmployeeBandBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_BENEFIT.UPDATE
            .replace('{bandId}', String(bandId)), payload);
    deleteEmployeeBandBenefitStatus = (bandId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_BENEFIT.DELETE
            .replace('{bandId}', String(bandId)));

    getEmployeeBandBonus = (query?: QueryEmployeeBandBonusDto) => HttpClient
        .get<EmployeeBandBonusPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_BONUS.GET, query);
    getEmployeeBandBonusById = (bandId: number) => HttpClient
        .get<{ data:EmployeeBandBonusDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_BONUS.DETAIL
            .replace('{bandId}', String(bandId)));
    createEmployeeBandBonus = (payload: CreateEmployeeBandBonusDto) => HttpClient
        .post<{ data:EmployeeBandBonusDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_BONUS.CREATE, payload);
    updateEmployeeBandBonus = (bandId: number, payload: UpdateEmployeeBandBonusDto) => HttpClient
        .patch<{ data:EmployeeBandBonusDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_BONUS.UPDATE
            .replace('{bandId}', String(bandId)), payload);
    deleteEmployeeBandBonus = (bandId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_BONUS.DELETE
            .replace('{bandId}', String(bandId)));

    getEmployeeBandLoanBenefit = (query?: QueryEmployeeBandLoanBenefitDto) => HttpClient
        .get<EmployeeBandLoanBenefitPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_LOAN_BENEFIT.GET, query);
    getEmployeeBandLoanBenefitById = (bandId: number) => HttpClient
        .get<{ data:EmployeeBandLoanBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_LOAN_BENEFIT.DETAIL
            .replace('{bandId}', String(bandId)));
    createEmployeeBandLoanBenefit = (payload: CreateEmployeeBandLoanBenefitDto) => HttpClient
        .post<{ data:EmployeeBandLoanBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_LOAN_BENEFIT.CREATE, payload);
    updateEmployeeBandLoanBenefitStatus = (bandId: number, payload: UpdateEmployeeBandLoanBenefitStatusDto) => HttpClient
        .patch<{ data:EmployeeBandLoanBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_LOAN_BENEFIT.UPDATE
            .replace('{bandId}', String(bandId)), payload);
    deleteEmployeeBandLoanBenefitStatus = (bandId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_LOAN_BENEFIT.DELETE
            .replace('{bandId}', String(bandId)));

    getEmployeeBandSavingsScheme = (query?: QueryEmployeeBandSavingsSchemeDto) => HttpClient
        .get<EmployeeBandSavingsSchemePaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_SAVINGS_SCHEME.GET, query);
    getEmployeeBandSavingsSchemeById = (bandId: number) => HttpClient
        .get<{ data:EmployeeBandSavingsSchemeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_SAVINGS_SCHEME.DETAIL
            .replace('{bandId}', String(bandId)));
    createEmployeeBandSavingsScheme = (payload: CreateEmployeeBandSavingsSchemeDto) => HttpClient
        .post<{ data:EmployeeBandSavingsSchemeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_SAVINGS_SCHEME.CREATE, payload);
    updateEmployeeBandSavingsSchemeStatus = (bandId: number, payload: UpdateEmployeeBandSavingsSchemeStatusDto) => HttpClient
        .patch<{ data:EmployeeBandSavingsSchemeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_SAVINGS_SCHEME.UPDATE
            .replace('{bandId}', String(bandId)), payload);
    deleteEmployeeBandSavingsSchemeStatus = (bandId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_SAVINGS_SCHEME.DELETE
            .replace('{bandId}', String(bandId)));

    getBenefitTax = (query?: QueryBenefitTaxDto) => HttpClient
        .get<BenefitTaxPaginator>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TAX.GET, query);
    getBenefitTaxById = (benefitTaxId: number) => HttpClient
        .get<{ data:BenefitTaxDto }>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TAX.DETAIL.replace('{benefitTaxId}', String(benefitTaxId)));
    createBenefitTax = (payload: CreateBenefitTaxDto) => HttpClient
        .post<{ data:BenefitTaxDto }>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TAX.CREATE, payload);
    updateBenefitTax = (benefitTaxId: number, payload: UpdateBenefitTaxDto) => HttpClient
        .patch<{ data:BenefitTaxDto }>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TAX.UPDATE
            .replace('{benefitTaxId}', String(benefitTaxId)), payload);
    deleteBenefitTax = (benefitTaxId: number) => HttpClient
        .delete<{ data:BenefitTaxDto }>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TAX.UPDATE
            .replace('{benefitTaxId}', String(benefitTaxId)));

    getTaxRate = (query?: QueryTaxRateDto) => HttpClient
        .get<TaxRatePaginator>(ENDPOINTS.PROCESSING_COMPANY.TAX_RATE.GET, query);
    getTaxRateById = (taxRateId: number) => HttpClient
        .get<{ data:TaxRateDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RATE.DETAIL.replace('{taxRateId}', String(taxRateId)));
    createTaxRate = (payload: CreateTaxRateDto) => HttpClient
        .post<{ data:TaxRateDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RATE.CREATE, payload);
    updateTaxRate = (taxRateId: number, payload: UpdateTaxRateDto) => HttpClient
        .patch<{ data:TaxRateDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RATE.UPDATE
            .replace('{taxRateId}', String(taxRateId)), payload);
    deleteTaxRate = (taxRateId: number) => HttpClient
        .delete<{ data:TaxRateDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RATE.UPDATE
            .replace('{taxRateId}', String(taxRateId)));

    getTaxRelief = (query?: QueryTaxReliefDto) => HttpClient
        .get<TaxReliefPaginator>(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF.GET, query);
    getTaxReliefById = (taxReliefId: number) => HttpClient
        .get<{ data:TaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF.DETAIL
            .replace('{taxReliefId}', String(taxReliefId)));
    createTaxRelief = (payload: CreateTaxReliefDto) => HttpClient
        .post<{ data:TaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF.CREATE, payload);
    updateTaxRelief = (taxReliefId: number, payload: UpdateTaxReliefDto) => HttpClient
        .patch<{ data:TaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF.UPDATE
            .replace('{taxReliefId}', String(taxReliefId)), payload);
    deleteTaxRelief = (taxReliefId: number) => HttpClient
        .delete<{ data:TaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF.UPDATE
            .replace('{taxReliefId}', String(taxReliefId)));

    getTaxReliefType = (query?: QueryTaxReliefTypeDto) => HttpClient
        .get<TaxReliefTypePaginator>(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF_TYPE.GET, query);
    getTaxReliefTypeSearch = (query?: SearchTaxReliefTypeDto) => HttpClient
        .get<TaxReliefTypePaginator>(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF_TYPE.SEARCH, query);
    getTaxReliefTypeById = (taxReliefTypeId: number) => HttpClient
        .get<{ data:TaxReliefTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF_TYPE.DETAIL
            .replace('{taxReliefTypeId}', String(taxReliefTypeId)));
    createTaxReliefType = (payload: CreateTaxReliefTypeDto) => HttpClient
        .post<{ data:TaxReliefTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF_TYPE.CREATE, payload);
    updateTaxReliefType = (taxReliefTypeId: number, payload: UpdateTaxReliefTypeDto) => HttpClient
        .patch<{ data:TaxReliefTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF_TYPE.UPDATE
            .replace('{taxReliefTypeId}', String(taxReliefTypeId)), payload);
    deleteTaxReliefType = (taxReliefTypeId: number) => HttpClient
        .delete<{ data:TaxReliefTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_RELIEF_TYPE.UPDATE
            .replace('{taxReliefTypeId}', String(taxReliefTypeId)));

    getBackPay = (query?: QueryBackPayDto) => HttpClient
        .get<BackPayPaginator>(ENDPOINTS.PROCESSING_COMPANY.BACK_PAY.GET, query);
    getBackPaySearch = (query?: SearchBackPayDto) => HttpClient
        .get<BackPayPaginator>(ENDPOINTS.PROCESSING_COMPANY.BACK_PAY.SEARCH, query);
    getBackPayById = (backPayId: number) => HttpClient
        .get<{ data: BackPayDto }>(ENDPOINTS.PROCESSING_COMPANY.BACK_PAY.DETAIL
            .replace('{backPayId}', String(backPayId)));
    createBackPay = (payload: CreateBackPayDto) => HttpClient
        .post<{ data: BackPayDto }>(ENDPOINTS.PROCESSING_COMPANY.BACK_PAY.CREATE, payload);
    updateBackPay = (backPayId: number, payload: UpdateBackPayDto) => HttpClient
        .patch<{ data: BackPayDto }>(ENDPOINTS.PROCESSING_COMPANY.BACK_PAY.UPDATE
            .replace('{backPayId}', String(backPayId)), payload);
    deleteBackPay = (backPayId: number) => HttpClient
        .delete<{ data: BackPayDto }>(ENDPOINTS.PROCESSING_COMPANY.BACK_PAY.UPDATE
            .replace('{backPayId}', String(backPayId)));

    getBenefitType = (query?: QueryBenefitTypeDto) => HttpClient
        .get<BenefitTypePaginator>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TYPE.GET, query);
    getBenefitTypeSearch = (query?: SearchBenefitTypeDto) => HttpClient
        .get<BenefitTypePaginator>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TYPE.SEARCH, query);
    getBenefitTypeById = (benefitTypeId: number) => HttpClient
        .get<{ data: BenefitTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TYPE.DETAIL
            .replace('{benefitTypeId}', String(benefitTypeId)));
    createBenefitType = (payload: CreateBenefitTypeDto) => HttpClient
        .post<{ data: BenefitTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TYPE.CREATE, payload);
    updateBenefitType = (benefitTypeId: number, payload: UpdateBenefitTypeDto) => HttpClient
        .patch<{ data: BenefitTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TYPE.UPDATE
            .replace('{benefitTypeId}', String(benefitTypeId)), payload);
    deleteBenefitType = (benefitTypeId: number) => HttpClient
        .delete<{ data: BenefitTypeDto }>(ENDPOINTS.PROCESSING_COMPANY.BENEFIT_TYPE.UPDATE
            .replace('{benefitTypeId}', String(benefitTypeId)));

    getIndustry = (query?: QueryIndustryDto) => HttpClient
        .get<IndustryPaginator>(ENDPOINTS.PROCESSING_COMPANY.INDUSTRIES.GET, query);
    getIndustrySearch = (query?: SearchIndustryDto) => HttpClient
        .get<IndustryPaginator>(ENDPOINTS.PROCESSING_COMPANY.INDUSTRIES.SEARCH, query);
    getIndustryById = (industryId: number) => HttpClient
        .get<{ data: IndustryDto }>(ENDPOINTS.PROCESSING_COMPANY.INDUSTRIES.DETAIL
            .replace('{industryId}', String(industryId)));
    createIndustry = (payload: CreateIndustryDto) => HttpClient
        .post<{ data: IndustryDto }>(ENDPOINTS.PROCESSING_COMPANY.INDUSTRIES.CREATE, payload);
    updateIndustry = (industryId: number, payload: UpdateIndustryDto) => HttpClient
        .patch<{ data: IndustryDto }>(ENDPOINTS.PROCESSING_COMPANY.INDUSTRIES.UPDATE
            .replace('{industryId}', String(industryId)), payload);
    deleteIndustry = (industryId: number) => HttpClient
        .delete<{ data: IndustryDto }>(ENDPOINTS.PROCESSING_COMPANY.INDUSTRIES.UPDATE
            .replace('{industryId}', String(industryId)));


    getIndustryBenefitTax = (query?: QueryIndustryBenefitTaxDto) => HttpClient
        .get<IndustryBenefitTaxPaginator>(ENDPOINTS.PROCESSING_COMPANY.INDUSTRY_BENEFIT_TAXES.GET, query);
    getIndustryBenefitTaxById = (industryBenefitTaxId: number) => HttpClient
        .get<{ data: IndustryBenefitTaxDto }>(ENDPOINTS.PROCESSING_COMPANY.INDUSTRY_BENEFIT_TAXES.DETAIL
            .replace('{industryBenefitTaxId}', String(industryBenefitTaxId)));
    createIndustryBenefitTax = (payload: CreateIndustryBenefitTaxDto) => HttpClient
        .post<{ data: IndustryBenefitTaxDto }>(ENDPOINTS.PROCESSING_COMPANY.INDUSTRY_BENEFIT_TAXES.CREATE, payload);
    updateIndustryBenefitTax = (industryBenefitTaxId: number, payload: UpdateIndustryBenefitTaxDto) => HttpClient
        .patch<{ data: IndustryBenefitTaxDto }>(ENDPOINTS.PROCESSING_COMPANY.INDUSTRY_BENEFIT_TAXES.UPDATE
            .replace('{industryBenefitTaxId}', String(industryBenefitTaxId)), payload);
    deleteIndustryBenefitTax = (industryBenefitTaxId: number) => HttpClient
        .delete<{ data: IndustryBenefitTaxDto }>(ENDPOINTS.PROCESSING_COMPANY.INDUSTRY_BENEFIT_TAXES.UPDATE
            .replace('{industryBenefitTaxId}', String(industryBenefitTaxId)));


    getTaxCode = (query?: QueryTaxCodeDto) => HttpClient
        .get<TaxCodePaginator>(ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.GET, query);
    getTaxCodeSearch = (query?: SearchTaxCodeDto) => HttpClient
        .get<TaxCodePaginator>(ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.SEARCH, query);
    getTaxCodeById = (taxCodeId: number) => HttpClient
        .get<{ data: TaxCodeDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.DETAIL
            .replace('{taxCodeId}', String(taxCodeId)));
    createTaxCode = (payload: CreateTaxCodeDto) => HttpClient
        .post<{ data: TaxCodeDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.CREATE, payload);
    updateTaxCode = (taxCodeId: number, payload: UpdateTaxCodeDto) => HttpClient
        .patch<{ data: TaxCodeDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.UPDATE
            .replace('{taxCodeId}', String(taxCodeId)), payload);
    deleteTaxCode = (taxCodeId: number) => HttpClient
        .delete<{ data: TaxCodeDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.DELETE
            .replace('{taxCodeId}', String(taxCodeId)));
    setDefaultTaxCode = (taxCodeId: number, payload: SetDefaultTaxCodeDto) => HttpClient
        .put<{ data: TaxCodeDto }>(ENDPOINTS.PROCESSING_COMPANY.TAX_CODE.SET_DEFAULT
            .replace('{taxCodeId}', String(taxCodeId)), payload);

    getCompLevelLeavePackage = (query?: QueryCompanyLevelLeavePackageDto) => HttpClient
        .get<CompanyLevelLeavePackagePaginator>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL_LEAVE_PACKAGE.GET, query);
    getCompLevelLeavePackageById = (compLevelLeavePackageId: number) => HttpClient
        .get<{ data: CompanyLevelLeavePackageDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL_LEAVE_PACKAGE.DETAIL
            .replace('{compLevelLeavePackageId}', String(compLevelLeavePackageId)));
    createCompLevelLeavePackage = (payload: CreateCompanyLevelLeavePackageDto) => HttpClient
        .post<{ data: CompanyLevelLeavePackageDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL_LEAVE_PACKAGE.CREATE, payload);
    deleteCompLevelLeavePackage = (compLevelLeavePackageId: number) => HttpClient
        .delete<{ data: CompanyLevelLeavePackageDto}>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL_LEAVE_PACKAGE.DELETE
            .replace('{compLevelLeavePackageId}', String(compLevelLeavePackageId)));
    
    getCompLevel = (query?: QueryCompanyLevelDto) => HttpClient
        .get<CompanyLevelPaginator>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL.GET, query);
    getCompLevelById = (companyLevelId: number) => HttpClient
        .get<{ data: CompanyLevelDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL.DETAIL
            .replace('{companyLevelId}', String(companyLevelId)));
    createCompLevel = (payload: CreateCompanyLevelDto) => HttpClient
        .post<{ data: CompanyLevelDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL.CREATE, payload);
    updateCompLevel = (companyLevelId: number, payload: UpdateCompanyLevelDto) => HttpClient
    .patch<{ data: CompanyLevelDto }>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL.UPDATE
        .replace('{companyLevelId}', String(companyLevelId)), payload);
    deleteCompLevelById = (companyLevelId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.COMPANY_LEVEL.DELETE
            .replace('{companyLevelId}', String(companyLevelId)));
    
    getEmployeeAccountInfo = (query?: QueryEmployeeAccountInfoDto) => HttpClient
        .get<EmployeeAccountInfoPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_ACCOUNT_INFO.GET, query);
    getEmployeeAccountInfoById = (accountId: number) => HttpClient
        .get<{ data: EmployeeAccountInfoDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_ACCOUNT_INFO.DETAIL
            .replace('{accountId}', String(accountId)));
    createEmployeeAccountInfo = (payload: CreateEmployeeAccountInfoDto) => HttpClient
        .post<{ data: EmployeeAccountInfoDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_ACCOUNT_INFO.CREATE, payload);
    updateEmployeeAccountInfo = (accountId: number, payload: UpdateEmployeeAccountInfoDto) => HttpClient
    .patch<{ data: EmployeeAccountInfoDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_ACCOUNT_INFO.UPDATE
        .replace('{accountId}', String(accountId)), payload);
    deleteEmployeeAccountInfoById = (accountId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_ACCOUNT_INFO.DELETE
            .replace('{accountId}', String(accountId)));

    getEmployeeBandPension = (query?: QueryEmployeeBandPensionDto) => HttpClient
        .get<EmployeeBandPensionPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_PENSION.GET, query);
    getEmployeeBandPensionById = (bandPensionId: number) => HttpClient
        .get<{ data:EmployeeBandPensionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_PENSION.DETAIL
            .replace('{bandPensionId}', String(bandPensionId)));
    createEmployeeBandPension = (payload: CreateEmployeeBandPensionDto) => HttpClient
        .post<{ data:EmployeeBandPensionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_PENSION.CREATE, payload);
    updateEmployeeBandPension = (bandPensionId: number, payload: UpdateEmployeeBandPensionStatusDto) => HttpClient
        .patch<{ data:EmployeeBandPensionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_PENSION.UPDATE
            .replace('{bandPensionId}', String(bandPensionId)), payload);
    deleteEmployeeBandPension = (bandPensionId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_PENSION.DELETE
            .replace('{bandPensionId}', String(bandPensionId)));

    getEmployeeBandCustomTaxRelief = (query?: QueryEmployeeBandCustomTaxReliefDto) => HttpClient
        .get<EmployeeBandCustomTaxReliefPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_CUSTOM_TAX_RELIEF.GET, query);
    getEmployeeBandCustomTaxReliefById = (empBandCustomTaxReliefId: number) => HttpClient
        .get<{ data: EmployeeBandCustomTaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_CUSTOM_TAX_RELIEF.DETAIL
            .replace('{empBandCustomTaxReliefId}', String(empBandCustomTaxReliefId)));
    createEmployeeBandCustomTaxRelief = (payload: CreateEmployeeBandCustomTaxReliefDto) => HttpClient
        .post<{ data: EmployeeBandCustomTaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_CUSTOM_TAX_RELIEF.CREATE, payload);
    updateEmployeeBandCustomTaxRelief = (empBandCustomTaxReliefId: number, payload: UpdateEmployeeBandCustomTaxReliefStatusDto) => HttpClient
        .patch<{ data: EmployeeBandCustomTaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_CUSTOM_TAX_RELIEF.UPDATE
            .replace('{empBandCustomTaxReliefId}', String(empBandCustomTaxReliefId)), payload);
    deleteEmployeeBandCustomTaxRelief = (empBandCustomTaxReliefId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_CUSTOM_TAX_RELIEF.DELETE
            .replace('{empBandCustomTaxReliefId}', String(empBandCustomTaxReliefId)));

    getEmployeeBandExplicitDeductions = (query?: QueryEmployeeBandExplicitDeductionDto) => HttpClient
        .get<EmployeeBandExplicitDeductionPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_EXPLICIT_DEDUCTIONS.GET, query);
    getEmployeeBandExplicitDeductionsById = (empBandExplicitDeductionId: number) => HttpClient
        .get<{ data: EmployeeBandExplicitDeductionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_EXPLICIT_DEDUCTIONS.DETAIL
            .replace('{empBandExplicitDeductionId}', String(empBandExplicitDeductionId)));
    createEmployeeBandExplicitDeductions = (payload: CreateEmployeeBandExplicitDeductionDto) => HttpClient
        .post<{ data: EmployeeBandExplicitDeductionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_EXPLICIT_DEDUCTIONS.CREATE, payload);
    updateEmployeeBandExplicitDeductions = (empBandExplicitDeductionId: number, payload: UpdateEmployeeBandExplicitDeductionStatusDto) => HttpClient
        .patch<{ data: EmployeeBandExplicitDeductionDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_EXPLICIT_DEDUCTIONS.UPDATE
            .replace('{empBandExplicitDeductionId}', String(empBandExplicitDeductionId)), payload);
    deleteEmployeeBandExplicitDeductions = (empBandExplicitDeductionId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BAND_EXPLICIT_DEDUCTIONS.DELETE
            .replace('{empBandExplicitDeductionId}', String(empBandExplicitDeductionId)));

    getEmployeeBenefit = (query?: QueryEmployeeBenefitDto) => HttpClient
        .get<EmployeeBenefitPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BENEFIT.GET, query);
    getEmployeeBenefitById = (employeeBenefitId: number) => HttpClient
        .get<{ data: EmployeeBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BENEFIT.DETAIL
            .replace('{employeeBenefitId}', String(employeeBenefitId)));
    createEmployeeBenefit = (payload: CreateEmployeeBenefitDto) => HttpClient
        .post<{ data: EmployeeBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BENEFIT.CREATE, payload);
    updateEmployeeBenefit = (employeeBenefitId: number, payload: UpdateEmployeeBenefitStatusDto) => HttpClient
        .patch<{ data: EmployeeBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BENEFIT.UPDATE
            .replace('{employeeBenefitId}', String(employeeBenefitId)), payload);
    deleteEmployeeBenefit = (employeeBenefitId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_BENEFIT.DELETE
            .replace('{employeeBenefitId}', String(employeeBenefitId)));

    getEmployeeContract = (query?: QueryEmployeeContractDto) => HttpClient
        .get<EmployeeContractPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_CONTRACT.GET, query);
    getEmployeeContractById = (employeeContractId: number) => HttpClient
        .get<{ data: EmployeeContractDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_CONTRACT.DETAIL
            .replace('{employeeContractId}', String(employeeContractId)));
    createEmployeeContract = (payload: CreateEmployeeContractDto) => HttpClient
        .post<{ data: EmployeeContractDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_CONTRACT.CREATE, payload);
    updateEmployeeContract = (employeeContractId: number, payload: UpdateEmployeeContractDto) => HttpClient
        .patch<{ data: EmployeeContractDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_CONTRACT.UPDATE
            .replace('{employeeContractId}', String(employeeContractId)), payload);
    deleteEmployeeContract = (employeeContractId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_CONTRACT.DELETE
            .replace('{employeeContractId}', String(employeeContractId)));

    getEmployeeCustomTaxRelief = (query?: QueryEmployeeCustomTaxReliefDto) => HttpClient
        .get<EmployeeCustomTaxReliefPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_CUSTOM_TAX_RELIEF.GET, query);
    getEmployeeCustomTaxReliefById = (empCustomTaxReliefId: number) => HttpClient
        .get<{ data: EmployeeCustomTaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_CUSTOM_TAX_RELIEF.DETAIL
            .replace('{empCustomTaxReliefId}', String(empCustomTaxReliefId)));
    createEmployeeCustomTaxRelief = (payload: CreateEmployeeCustomTaxReliefDto) => HttpClient
        .post<{ data: EmployeeCustomTaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_CUSTOM_TAX_RELIEF.CREATE, payload);
    updateEmployeeCustomTaxRelief = (empCustomTaxReliefId: number, payload: UpdateEmployeeCustomTaxReliefDto) => HttpClient
        .patch<{ data: EmployeeCustomTaxReliefDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_CUSTOM_TAX_RELIEF.UPDATE
            .replace('{empCustomTaxReliefId}', String(empCustomTaxReliefId)), payload);
    deleteEmployeeCustomTaxRelief = (empCustomTaxReliefId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_CUSTOM_TAX_RELIEF.DELETE
            .replace('{empCustomTaxReliefId}', String(empCustomTaxReliefId)));

    getEmployeeLoanBenefit = (query?: QueryEmployeeLoanBenefitDto) => HttpClient
        .get<EmployeeLoanBenefitPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_LOAN_BENEFIT.GET, query);
    getEmployeeLoanBenefitById = (empLoanBenefitId: number) => HttpClient
        .get<{ data: EmployeeLoanBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_LOAN_BENEFIT.DETAIL
            .replace('{empLoanBenefitId}', String(empLoanBenefitId)));
    createEmployeeLoanBenefit = (payload: CreateEmployeeLoanBenefitDto) => HttpClient
        .post<{ data: EmployeeLoanBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_LOAN_BENEFIT.CREATE, payload);
    updateEmployeeLoanBenefit = (empLoanBenefitId: number, payload: UpdateEmployeeLoanBenefitStatusDto) => HttpClient
        .patch<{ data: EmployeeLoanBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_LOAN_BENEFIT.UPDATE
            .replace('{empLoanBenefitId}', String(empLoanBenefitId)), payload);
    deleteEmployeeLoanBenefit = (empLoanBenefitId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_LOAN_BENEFIT.DELETE
            .replace('{empLoanBenefitId}', String(empLoanBenefitId)));

    getEmployeeSavingsScheme = (query?: QueryEmployeeSavingsSchemeDto) => HttpClient
        .get<EmployeeSavingsSchemePaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_SAVINGS_SCHEMES.GET, query);
    getEmployeeSavingsSchemeById = (empSavingsSchemeId: number) => HttpClient
        .get<{ data: EmployeeSavingsSchemeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_SAVINGS_SCHEMES.DETAIL
            .replace('{empSavingsSchemeId}', String(empSavingsSchemeId)));
    createEmployeeSavingsScheme = (payload: CreateEmployeeSavingsSchemeDto) => HttpClient
        .post<{ data: EmployeeSavingsSchemeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_SAVINGS_SCHEMES.CREATE, payload);
    updateEmployeeSavingsScheme = (empSavingsSchemeId: number, payload: UpdateEmployeeSavingsSchemeStatusDto) => HttpClient
        .patch<{ data: EmployeeSavingsSchemeDto }>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_SAVINGS_SCHEMES.UPDATE
            .replace('{empSavingsSchemeId}', String(empSavingsSchemeId)), payload);
    deleteEmployeeSavingsScheme = (empSavingsSchemeId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_SAVINGS_SCHEMES.DELETE
            .replace('{empSavingsSchemeId}', String(empSavingsSchemeId))); 

    getErpIntegrationMapping = (query?: QueryErpIntegrationMappingDto) => HttpClient
        .get<ErpIntegrationMappingPaginator>(ENDPOINTS.PROCESSING_COMPANY.ERP_INTEGRATION_MAPPING.GET, query);
    getErpIntegrationMappingSearch = (query?: SearchErpIntegrationMappingDto) => HttpClient
        .get<ErpIntegrationMappingPaginator>(ENDPOINTS.PROCESSING_COMPANY.ERP_INTEGRATION_MAPPING.SEARCH, query);
    getErpIntegrationMappingById = (erpIntMappingId: number) => HttpClient
        .get<{ data: ErpIntegrationMappingDto }>(ENDPOINTS.PROCESSING_COMPANY.ERP_INTEGRATION_MAPPING.DETAIL
            .replace('{erpIntMappingId}', String(erpIntMappingId)));
    createErpIntegrationMapping = (payload: CreateErpIntegrationMappingDto) => HttpClient
        .post<{ data: ErpIntegrationMappingDto }>(ENDPOINTS.PROCESSING_COMPANY.ERP_INTEGRATION_MAPPING.CREATE, payload);
    updateErpIntegrationMapping = (erpIntMappingId: number, payload: UpdateErpIntegrationMappingDto) => HttpClient
        .patch<{ data: ErpIntegrationMappingDto }>(ENDPOINTS.PROCESSING_COMPANY.ERP_INTEGRATION_MAPPING.UPDATE
            .replace('{erpIntMappingId}', String(erpIntMappingId)), payload);
    deleteErpIntegrationMapping = (erpIntMappingId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.ERP_INTEGRATION_MAPPING.DELETE
            .replace('{erpIntMappingId}', String(erpIntMappingId)));

    getLoanBenefit = (query?: QueryLoanBenefitDto) => HttpClient
        .get<LoanBenefitPaginator>(ENDPOINTS.PROCESSING_COMPANY.LOAN_BENEFIT.GET, query);
    getLoanBenefitSearch = (query?: SearchLoanBenefitDto) => HttpClient
        .get<LoanBenefitPaginator>(ENDPOINTS.PROCESSING_COMPANY.LOAN_BENEFIT.SEARCH, query);
    getLoanBenefitById = (loanBenefitId: number) => HttpClient
        .get<{ data: LoanBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.LOAN_BENEFIT.DETAIL
            .replace('{loanBenefitId}', String(loanBenefitId)));
    createLoanBenefit = (payload: CreateLoanBenefitDto) => HttpClient
        .post<{ data: LoanBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.LOAN_BENEFIT.CREATE, payload);
    updateLoanBenefit = (loanBenefitId: number, payload: UpdateLoanBenefitDto) => HttpClient
        .patch<{ data: LoanBenefitDto }>(ENDPOINTS.PROCESSING_COMPANY.LOAN_BENEFIT.UPDATE
            .replace('{loanBenefitId}', String(loanBenefitId)), payload);
    deleteLoanBenefit = (loanBenefitId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.LOAN_BENEFIT.DELETE
            .replace('{loanBenefitId}', String(loanBenefitId)));

    getLoan = (query?: QueryLoanDto) => HttpClient
        .get<LoanPaginator>(ENDPOINTS.PROCESSING_COMPANY.LOAN.GET, query);
    getLoanById = (loanId: number) => HttpClient
        .get<{ data: LoanDto }>(ENDPOINTS.PROCESSING_COMPANY.LOAN.DETAIL
            .replace('{loanId}', String(loanId)));
    createLoan = (payload: CreateLoanDto) => HttpClient
        .post<{ data: LoanDto }>(ENDPOINTS.PROCESSING_COMPANY.LOAN.CREATE, payload);
    updateLoan = (loanId: number, payload: UpdateLoanDto) => HttpClient
        .patch<{ data: LoanDto }>(ENDPOINTS.PROCESSING_COMPANY.LOAN.UPDATE
            .replace('{loanId}', String(loanId)), payload);
    deleteLoan = (loanId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.LOAN.DELETE
            .replace('{loanId}', String(loanId)));

    getPartialSalary = (query?: QueryPartialSalaryDto) => HttpClient
        .get<PartialSalaryPaginator>(ENDPOINTS.PROCESSING_COMPANY.PARTIAL_SALARY.GET, query);
    getPartialSalaryGivenPayPeriod = (payPeriodId: number, query?: QueryPartialSalaryGivenPayPeriodDto) => HttpClient
        .get<PartialSalaryPaginator>(ENDPOINTS.PROCESSING_COMPANY.PARTIAL_SALARY.GET_WITH_PAY_PERIOD
            .replace('{payPeriodId}', String(payPeriodId)), query);
    getPartialSalaryById = (partialSalaryId: number) => HttpClient
        .get<{ data: PartialSalaryDto }>(ENDPOINTS.PROCESSING_COMPANY.PARTIAL_SALARY.DETAIL
            .replace('{partialSalaryId}', String(partialSalaryId)));
    createPartialSalary = (payload: CreatePartialSalaryDto) => HttpClient
        .post<{ data: PartialSalaryDto }>(ENDPOINTS.PROCESSING_COMPANY.PARTIAL_SALARY.CREATE, payload);
    updatePartialSalary = (partialSalaryId: number, payload: UpdatePartialSalaryDto) => HttpClient
        .patch<{ data: PartialSalaryDto }>(ENDPOINTS.PROCESSING_COMPANY.PARTIAL_SALARY.UPDATE
            .replace('{partialSalaryId}', String(partialSalaryId)), payload);
    deletePartialSalary = (partialSalaryId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.PARTIAL_SALARY.DELETE
            .replace('{partialSalaryId}', String(partialSalaryId)));

    getSalaryAdvance = (query?: QuerySalaryAdvanceDto) => HttpClient
        .get<SalaryAdvancePaginator>(ENDPOINTS.PROCESSING_COMPANY.SALARY_ADVANCE.GET, query);
    getSalaryAdvanceById = (salaryAdvanceId: number) => HttpClient
        .get<{ data: SalaryAdvanceDto }>(ENDPOINTS.PROCESSING_COMPANY.SALARY_ADVANCE.DETAIL
            .replace('{salaryAdvanceId}', String(salaryAdvanceId)));
    createSalaryAdvance = (payload: CreateSalaryAdvanceDto) => HttpClient
        .post<{ data: SalaryAdvanceDto }>(ENDPOINTS.PROCESSING_COMPANY.SALARY_ADVANCE.CREATE, payload);
    updateSalaryAdvance = (salaryAdvanceId: number, payload: UpdateSalaryAdvanceDto) => HttpClient
        .patch<{ data: SalaryAdvanceDto }>(ENDPOINTS.PROCESSING_COMPANY.SALARY_ADVANCE.UPDATE
            .replace('{salaryAdvanceId}', String(salaryAdvanceId)), payload);
    deleteSalaryAdvance = (salaryAdvanceId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.SALARY_ADVANCE.DELETE
            .replace('{salaryAdvanceId}', String(salaryAdvanceId)));

    getDepartment = (companyId: number, query?: QueryDepartmentDto) => HttpClient
        .get<DepartmentPaginator>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT.GET
            .replace('{companyId}', String(companyId)), query);
    getDepartmentSearch = (companyId: number, query?: SearchDepartmentDto) => HttpClient
        .get<DepartmentPaginator>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT.SEARCH
            .replace('{companyId}', String(companyId)), query);
    getDepartmentById = (companyId: number, departmentId: number) => HttpClient
        .get<{ data:DepartmentDto }>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT.DETAIL
            .replace('{companyId}', String(companyId)).replace('{departmentId}', String(departmentId)));
    createDepartment = (companyId: number, payload: CreateDepartmentDto) => HttpClient
        .post<{ data:DepartmentDto }>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT.CREATE
            .replace('{companyId}', String(companyId)), payload);
    updateDepartment = (companyId: number, departmentId: number, payload: UpdateDepartmentDto) => HttpClient
        .patch<{ data:DepartmentDto }>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT.UPDATE
            .replace('{companyId}', String(companyId))
            .replace('{departmentId}', String(departmentId)), payload);
    deleteDepartmentById = (companyId: number, departmentId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT.DELETE
            .replace('{companyId}', String(companyId)).replace('{departmentId}', String(departmentId)));

    getDepartmentLeadership = (companyId: number, query?: QueryDepartmentLeadershipDto) => HttpClient
        .get<DepartmentLeadershipPaginator>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.GET
            .replace('{companyId}', String(companyId)), query);
    unlinkDepartmentLeadership = (companyId: number, leadershipId: number) => HttpClient
        .delete<DepartmentLeadershipDto>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.UNLINK
            .replace('{companyId}', String(companyId)).replace('{leadershipId}', String(leadershipId)));
    getDepartmentLeadershipById = (companyId: number, leadershipId: number) => HttpClient
        .get<{ data:DepartmentLeadershipDto }>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.DETAIL
            .replace('{companyId}', String(companyId)).replace('{leadershipId}', String(leadershipId)));
    createDepartmentLeadership = (companyId: number, payload: CreateDepartmentLeadershipDto) => HttpClient
        .post<{ data:DepartmentLeadershipDto }>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.CREATE
            .replace('{companyId}', String(companyId)), payload);
    updateDepartmentLeadership = (companyId: number, leadershipId: number, payload: UpdateDepartmentLeadershipDto) => HttpClient
        .patch<{ data:DepartmentLeadershipDto }>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.UPDATE
            .replace('{companyId}', String(companyId))
            .replace('{leadershipId}', String(leadershipId)), payload);
    deleteDepartmentLeadershipById = (companyId: number, leadershipId: number) => HttpClient
        .delete<void>(ENDPOINTS.PROCESSING_COMPANY.DEPARTMENT_LEADERSHIP.DELETE
            .replace('{companyId}', String(companyId)).replace('{leadershipId}', String(leadershipId)));

    getEmployeeQualificationHistory = (query?: QueryEmployeeQualificationHistoryDto) => HttpClient
        .get<EmployeeQualificationHistoryPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_QUALIFICATIONS, query);
    getEmployeeJobTitleHistory = (query?: QueryEmployeeJobTitleHistoryDto) => HttpClient
        .get<EmployeeJobTitleHistoryPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_JOB_TITLES, query);
    getEmployeeNotchHistory = (query?: QueryEmployeeNotchHistoryDto) => HttpClient
        .get<EmployeeNotchHistoryPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_NOTCHES, query);
    getEmployeeBandHistory = (query?: QueryEmployeeBandHistoryDto) => HttpClient
        .get<EmployeeBandHistoryPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_BANDS, query);
    getEmployeeBasicSalaryHistory = (query?: QueryEmployeeBasicSalaryHistoryDto) => HttpClient
        .get<EmployeeBasicSalaryHistoryPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_BASIC_SALARY, query);
    getEmployeeEngagement = (query?: QueryEmployeeEngagementDto) => HttpClient
        .get<EmployeeEngagementPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_ENGAGEMENTS, query);
    getEmployeeDisengagement = (query?: QueryEmployeeDisengagementDto) => HttpClient
        .get<EmployeeDisengagementPaginator>(ENDPOINTS.PROCESSING_COMPANY.EMPLOYEE_HISTORY.GET_DISENGAGEMENTS, query);

}