import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { formatDateAndTime } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Name",
		width: 280,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "country",
		headerName: "Country",
		width: 250,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2">
					{params.row.country ?
						<p>
							{params.row.country.name}
						</p> :
						<p>--</p>
					}
				</div>
			);
		},
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "modifiedAt",
		headerName: "Date Modified",
		width: 240,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const modifiedOn = formatDateAndTime(params);
			return modifiedOn;
		},
	},
	{
		field: "actions",
		headerName: "Actions",
		cellClassName: "actions",
		headerClassName: "super-app-theme--header",
	}
];
