import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IAddEditTableForm } from "../../../../helpers/appHelpers";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import FormDescriptionInput from "../../../../components/form_fields/FormDescriptionInput";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { LeaveTypeDto } from "../../../../models/leave-type.dto";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import { useCreateLeavePackage, useUpdateLeavePackage } from "../../../../services/leave-package.service";
import { MenuItem, TextField } from "@mui/material";
import { CompanyLevel } from "../../../../models/company-level.dto";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useUserStore from "../../../../state-management/useUserStore";
import FormCodeInput from "../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
	code: string;
	name: string;
    colorCode: string;
	description: string;
    leaveTypeId: number
    maxDays: number
    carryOverDaysValue: number
    carryOverDaysPercent: number
    paid: boolean
    redeemable: boolean
    accrued: boolean
}
interface LeavePackagesModalFormProps extends IAddEditTableForm {
    leaveTypes: LeaveTypeDto[];
    companyLevels: CompanyLevel[];
}
const LeavePackagesModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	leaveTypes,
	companyLevels,
}: LeavePackagesModalFormProps) => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			code: isEditForm ? formDataValues?.code : "",
			name: isEditForm ? formDataValues?.name : "",
			description: isEditForm ? formDataValues?.description : "",
			leaveTypeId: isEditForm ? formDataValues?.leaveTypeId : undefined,
			maxDays: isEditForm ? formDataValues?.maxDays : undefined,
			carryOverDaysValue: isEditForm ? formDataValues?.carryOverDaysValue : undefined,
			carryOverDaysPercent: isEditForm ? formDataValues?.carryOverDaysPercent : undefined,
			paid: isEditForm ? formDataValues?.paid : undefined,
			redeemable: isEditForm ? formDataValues?.redeemable : undefined,
			accrued: isEditForm ? formDataValues?.accrued : undefined,
		}
	});
	
	const [companyLevelIds, setCompanyLevelIds] = useState<string[]>([]);

	const handleCompanyLevelIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setCompanyLevelIds(typeof value === "string" ? value.split(",") : value);
	}

	const { mutate: createLeavePackage } = useCreateLeavePackage();
	const { mutate: updateLeavePackage } = useUpdateLeavePackage();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);

		if (isEditForm) {
			updateLeavePackage(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false)
				}
			);
		} else {
			createLeavePackage(
				{ 
					payload: { 
						companyId: companyProfile.companyId, 
						...data,
						companyLevelIds,
					} 
				}, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false)
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form className="mt-4">
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							defaultValue={isEditForm ? formDataValues?.name : ""}
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Leave Type" for="leaveTypeId" />
						<select
							defaultValue={isEditForm ? formDataValues?.leaveTypeId : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.leaveTypeId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("leaveTypeId", {
								required: "Select leave type here",
								valueAsNumber: true
							})}
						>
							<option value="">Select Leave Type</option>
							{leaveTypes.map((leaveType) => (
								<option 
									key={leaveType.id} 
									value={leaveType.id}
								>
									{leaveType.name}
								</option>
							))}
						</select>
						{errors.leaveTypeId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.leaveTypeId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Maximum Days" for="maxDays" />
						<input
							defaultValue={isEditForm ? formDataValues?.maxDays : undefined}
							placeholder={"0"}
							type="number"
							{...register("maxDays", {
								required: "Enter maximum days here",
								valueAsNumber: true
							})}
							id="maxDays"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.maxDays
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.maxDays && (
							<p className="text-red-500 text-sm mt-1">{errors.maxDays.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Carry Over Days Amount" for="carryOverDaysValue" optional />
						<MoneyInput 
							attributes={{
								id: "carryOverDaysValue",
								placeholder: "0"
							}}
							register={register}
							name="carryOverDaysValue"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.carryOverDaysValue : undefined}
							required={false}
							error={errors.carryOverDaysValue}
						/>
					</div>
					<div className="w-full">
						<Label title="Carry Over Days Percentage" for="carryOverDaysPercent" optional />
						<div className="flex items-center h-14 mt-2">
							<input
								defaultValue={
									isEditForm
										? formDataValues?.carryOverDaysPercent
										: undefined
								}
								placeholder={"0"}
								type="number"
								{...register("carryOverDaysPercent", {
									required: false,
									valueAsNumber: true
								})}
								id="carryOverDaysPercent"
								className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
									errors.carryOverDaysPercent
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							<div className="h-full w-28 flex items-center justify-center text-sm rounded-r-lg text-white bg-black px-2">
								%
							</div>
						</div>
					</div>
					{!isEditForm && (
						<div className="w-full">
							<Label title="Company Levels" for="companyLevelIds" optional />
							<div className="mt-2"></div>
							<TextField
								sx={{
									fontFamily: "Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto"
								}}
								select
								value={companyLevelIds}
								onChange={handleCompanyLevelIdChange}
								className="w-full bg-secondary-gray rounded-lg h-14 px-4 text-sm border-none outline-none"
								SelectProps={{ 
									multiple: true,
									displayEmpty: true,
									renderValue: (selected: any) => {
										if (selected.length === 0) {
											return <span className="text-sm">Select Company Levels</span>
										}
										const levels = selected.map(id => {
											const level = companyLevels.find(level => level.id === id);
											return level ? `${level.levelName}` : '';
										});
										return <span className="text-sm">{levels.join(', ')}</span>;
									}
								}}
							>
								{companyLevels.map((level) => (
									<MenuItem 
										key={level.id} 
										value={level.id}
									>
										{level.levelName}
									</MenuItem>
								))}
							</TextField>
						</div>
					)}
				</div>
				<div className="w-full mt-6 flex flex-col gap-6">
					<div className="flex space-x-3 items-center">
						<input 
							type="checkbox"
							{...register("paid", {
								required: false,
							})}
							id="paid"
						/>
						<label htmlFor="paid">Paid</label>
					</div>
					<div className="flex space-x-3 items-center">
						<input 
							type="checkbox"
							{...register("redeemable", {
								required: false,
							})}
							id="redeemable"
						/>
						<label htmlFor="redeemable">Redeemable</label>
					</div>
					<div className="flex space-x-3 items-center">
						<input 
							type="checkbox"
							{...register("accrued", {
								required: false,
							})}
							id="accrued"
						/>
						<label htmlFor="accrued">Accrued</label>
					</div>
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading
								? "Uploading..."
								: isEditForm
								? "Update Leave Package"
								: "Add New Leave Package"
						}`}
						className={`px-8`}
						type={"submit"}
						onClick={handleSubmit(onSubmit)}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default LeavePackagesModalForm;
