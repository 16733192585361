import { Popover } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { BsCalendar4Week } from "react-icons/bs";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { APP_CONFIG } from "../../../../helpers/appHelpers";
import { useManageTableWithSearchParams } from "../../../../helpers/useManageTable";
import { useGetOrganization } from "../../../../services/organization.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { TransactionsColumns } from "../data";
import TransactionModal from "../modals/TransactionModal";
import { useGetOrgTransaction, useGetOrgTransactionSearch } from "../../../../services/organization-transaction.service";
import { OrganizationTransaction, TRANSACTION_TYPE } from "../../../../models/organization-transaction.dto";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { useGetInvoice } from "../../../../services/invoice.service";
import APISearchInput from "../../../../components/search/APISearchInput";
import { OrganizationOrderBy } from "../../../../models/organization.dto";

type FormAction = "CREATE" | "REVERSE";

const TransactionsView = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [formAction, setFormAction] = useState<FormAction>("CREATE");
	const { formDataValues, setFormData } = useEditFormStore();

	const { mutate: searchTransactions } = useGetOrgTransactionSearch();
	const {
        tableAPIRef,
        fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<OrganizationTransaction>(
		{ fetchHook: useGetOrgTransaction }
	);

	// Modal Form select fields API data source
    const options = {
		limit: APP_CONFIG.PAGE_LIMIT,
		page: 1,
		orderBy: "createdAt:desc" as any,
    }
	const { data: invoices } = useGetInvoice(options);
	const { data: organizations } = useGetOrganization({
		...options,
		orderBy: OrganizationOrderBy.NAME_ASC,
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setFormAction("CREATE");
		setOpenModal(true);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];

	const columnsWithAdditions = TransactionsColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

    return (
        <>
            <div className="flex justify-between mb-6">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchTransactions}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Date Created",
										fieldName: "createdAt",
									},
								]
							},
							{
								title: "Organization",
								fieldName: "organizationId",
								fieldType: "SELECT_FIELD",
								options: organizations?.data || [],
							},
							{
								title: "Type",
								fieldName: "type",
								fieldType: "CHECKBOX_LIST",
								options: TRANSACTION_TYPE,
							},
							{
								title: "Invoice",
								fieldName: "invoiceId",
								fieldType: "SELECT_FIELD",
								options: invoices?.data || [],
								mapText: "referenceCode"
							},
							{
								title: "Date Created",
								fieldName: "createdAt",
								fieldType: "DATE_RANGE",
							},
						]}
					/>
				</div>
                <div
                    className="flex items-center text-primary-blue cursor-pointer"
                    onClick={handleOpenModal}
                >
                    <AiOutlinePlus className="mr-1 text-base" />
                    <span className="text-sm">New Transaction</span>
                </div>
            </div>
            <CustomStyledTable
                rows={tableData || []}
                columns={columnsWithAdditions}
                apiRef={tableAPIRef}
                loading={isLoading}
                pageCount={pageCount}
				setAnchorEl={setAnchorEl}
			/>
			<Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        width: "auto",
                        borderRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                    },
                }}
            >
                <div className="flex flex-col justify-center p-3 text-secondary-darkgray">
                    <div
                        onClick={() => {
							if (formDataValues.type !== "CREDIT") {
								setFormAction("REVERSE");
								setOpenModal(true);
								handleClose();
							}
                        }}
                        className={`flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg
							${formDataValues.type !== "CREDIT" ? "cursor-not-allowed" : ""}
						`}
                    >
                        <BsCalendar4Week />
                        <p className="text-2sm">Reverse</p>
                    </div>
                </div>
            </Popover>
            
            {openModal && (
                <TransactionModal
                    open={openModal}
                    setOpen={setOpenModal}
                    formAction={formAction}
                    organizations={organizations?.data || []}
                />
            )}
        </>
    );
}
 
export default TransactionsView;