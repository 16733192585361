import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Avatar } from "@mui/material";
import { enumToStringConverter, formatDateAndTime } from "../../../helpers/appHelpers";

export const EmployeeWorkTimeColumns: (mobile: boolean) => GridColDef[] = (mobile) => ([
	{
		field: "payPeriod",
		headerName: "Pay Period",
		width: mobile ? 200 : 440,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},
	{
		field: "timeUnit",
		headerName: "Time Volume",
		width: mobile ? 200 : 420,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.row.timeValue + " " + enumToStringConverter(params.value),
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: mobile ? 220 : 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "modifiedAt",
		headerName: "Date Modified",
		width: mobile ? 220 : 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const modifiedOn = formatDateAndTime(params);
			return modifiedOn;
		},
	},
]);

export const SuperviseeWorkTimeColumns: (mobile: boolean) => GridColDef[] = (mobile) => ([
	{
		field: "employee",
		headerName: "Employee",
		headerClassName: "super-app-theme--header",
		width: mobile ? 250 : 300,
		renderCell: (params: any) => {
			const employee = params.value || undefined;
			return (
				<div className="flex items-center space-x-4">
					{employee && <>
						<Avatar
							src={employee.photoUrl || ""}
							alt="employee"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm">
							{employee.firstName + " " + employee.lastName}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "payPeriod",
		headerName: "Pay Period",
		width: mobile ? 200 : 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},
	{
		field: "timeUnit",
		headerName: "Time Volume",
		width: mobile ? 200 : 260,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.row.timeValue + " " + enumToStringConverter(params.value),
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: mobile ? 220 : 260,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "modifiedAt",
		headerName: "Date Modified",
		width: mobile ? 220 : 260,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const modifiedOn = formatDateAndTime(params);
			return modifiedOn;
		},
	},
]);

export const EmployeeOvertimeColumns: (mobile: boolean) => GridColDef[] = (mobile) => ([
	{
		field: "numberOfHours",
		headerName: "Number of Hours",
		width: mobile ? 200 : 340,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "overtime",
		headerName: "Overtime Type",
		width: mobile ? 220 : 350,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "payPeriod",
		headerName: "Pay Period",
		width: mobile ? 200 : 320,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: mobile ? 220 : 320,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "modifiedAt",
		headerName: "Date Modified",
		width: mobile ? 220 : 320,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const modifiedOn = formatDateAndTime(params);
			return modifiedOn;
		},
	},
]);

export const SuperviseeOvertimeColumns: (mobile: boolean) => GridColDef[] = (mobile) => ([
	{
		field: "employee",
		headerName: "Employee",
		headerClassName: "super-app-theme--header",
		width: mobile ? 250 : 300,
		renderCell: (params: any) => {
			const employee = params.value || undefined;
			return (
				<div className="flex items-center space-x-4">
					{employee && <>
						<Avatar
							src={employee.photoUrl || ""}
							alt="employee"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm">
							{employee.firstName + " " + employee.lastName}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "numberOfHours",
		headerName: "Number of Hours",
		width: mobile ? 200 : 220,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "overtime",
		headerName: "Overtime Type",
		width: mobile ? 220 : 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "payPeriod",
		headerName: "Pay Period",
		width: mobile ? 200 : 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: mobile ? 220 : 260,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "modifiedAt",
		headerName: "Date Modified",
		width: mobile ? 220 : 260,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const modifiedOn = formatDateAndTime(params);
			return modifiedOn;
		},
	},
]);