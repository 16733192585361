import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import {
	useCreateSavingsScheme,
	useUpdateSavingsScheme,
} from "../../../../services/savings-scheme.service";
import { APPLY_SAVINGS_CONTRIBUTION_ON } from "../../../../models/savings-scheme.dto";
import { enumToStringConverter, FREQUENCY, IAddEditTableForm } from "../../../../helpers/appHelpers";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import FormDescriptionInput from "../../../../components/form_fields/FormDescriptionInput";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { EmployeeBandDto } from "../../../../models/employee-band.dto";
import { EmployeeDto } from "../../../../models/employee.dto";
import { PayPeriodDto } from "../../../../models/pay-period.dto";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import APISelectField from "../../../../components/form_fields/APISelectField";
import FormCodeInput from "../../../../components/form_fields/FormCodeInput";
import MultiSelectField from "../../../../components/form_fields/MultiSelectField";
import { PATHS } from "../../../../routes/routes.paths";

interface IFormInputs {
	name: string;
	code: string;
	description: string;
	startPayPeriodId: number
	endPayPeriodId: number
    frequency: string
	employeeIds?: number[]
	employeeBandIds?: number[]
    applyEmployeeContributionOn: APPLY_SAVINGS_CONTRIBUTION_ON
    applyEmployerContributionOn: APPLY_SAVINGS_CONTRIBUTION_ON
    employeeContributionFigure: number
    employerContributionFigure: number
    prorate: string
    statutory: string
}

type SavingsModalFormProps = IAddEditTableForm & {
	payPeriods: PayPeriodDto[];
	employees: EmployeeDto[];
	employeeBands: EmployeeBandDto[];
}

const SavingsModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	payPeriods,
	employees,
	employeeBands,
}: SavingsModalFormProps) => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const [employeeFixedComponent, setEmployeeFixedComponent] = useState(
		(isEditForm && formDataValues?.applyEmployeeContributionOn.toLowerCase() === "fixed") || false
	);
	const [employerFixedComponent, setEmployerFixedComponent] = useState(
		(isEditForm && formDataValues?.applyEmployerContributionOn.toLowerCase() === "fixed") || false
	);

	const handleFixedComponent = (value: string, employee: boolean) => {
		if (value.toLowerCase() === "fixed") {
			employee ? setEmployeeFixedComponent(true) : setEmployerFixedComponent(true);
		} else {
			employee ? setEmployeeFixedComponent(false) : setEmployerFixedComponent(false);
		}
	};

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			description: isEditForm ? formDataValues?.description : "",
			startPayPeriodId: isEditForm ? formDataValues?.startPayPeriodId : "",
			endPayPeriodId: isEditForm ? formDataValues?.endPayPeriodId : "",
			frequency: isEditForm ? formDataValues?.frequency : "",
			applyEmployeeContributionOn: isEditForm ? formDataValues?.applyEmployeeContributionOn : "",
			applyEmployerContributionOn: isEditForm ? formDataValues?.applyEmployerContributionOn : "",
			employeeContributionFigure: isEditForm ? formDataValues?.employeeContributionFigure : "",
			employerContributionFigure: isEditForm ? formDataValues?.employerContributionFigure : "",
			prorate: isEditForm ? (formDataValues?.prorate ? "yes" : "no") : "",
			statutory: isEditForm ? (formDataValues?.statutory ? "yes" : "no") : "",
		}
	});
	
	const [employeeIds, setEmployeeIds] = useState<number[]>([]);
	const [employeeBandIds, setEmployeeBandIds] = useState<number[]>([]);

	const { mutate: createSavings } = useCreateSavingsScheme();
	const { mutate: updateSavings } = useUpdateSavingsScheme();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);
		data.prorate = data.prorate === "yes" ? true : false;
		data.statutory = data.statutory === "yes" ? true : false;

		if (isEditForm) {
			updateSavings(
				{ payload: data, id: formDataValues?.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			data.employeeIds = employeeIds.length ? employeeIds as any : undefined;
			data.employeeBandIds = employeeBandIds.length ? employeeBandIds as any : undefined;

			createSavings(
				{ payload: { ...data, companyId: currentCompany?.id as number } }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent open={open} title={title} handleClose={handleClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">
								{errors.code.message}
							</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput 
							register={register}
							attributes={
								{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Start Pay Period" for="startPayPeriodId" />
						<APISelectField 
							key="startPayPeriodId"
							attributes={{
								id: "startPayPeriodId",
							}}
							placeholder="Select Pay Period"
							register={register}
							name="startPayPeriodId"
							options={payPeriods}
							mapText="code"
							pathname="/dashboard/company-settings?view=payPeriods"
							required={"Select start pay period here"}
							error={errors.startPayPeriodId}
							valueAsNumber
						/>
						{errors.startPayPeriodId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.startPayPeriodId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="End Pay Period" for="endPayPeriodId" />
						<APISelectField 
							key="endPayPeriodId"
							attributes={{
								id: "endPayPeriodId",
							}}
							placeholder="Select Pay Period"
							register={register}
							name="endPayPeriodId"
							options={payPeriods}
							mapText="code"
							pathname="/dashboard/company-settings?view=payPeriods"
							required={"Select end pay period here"}
							error={errors.endPayPeriodId}
							valueAsNumber
						/>
						{errors.endPayPeriodId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.endPayPeriodId.message}
							</p>
						)}
					</div>
					{!isEditForm && (<>
						<div className="w-full">
							<Label title="Assign Employees" for="employeeIds" optional />
							<MultiSelectField
								key={"employeeIds"}
								options={employees}
								setResourceIds={setEmployeeIds}
								placeholder={"Select Employees"}
								pathname={PATHS.EMPLOYEES}
								required={false}
								mapTexts={["firstName", "lastName"]}
							/>
						</div>
						<div className="w-full">
							<Label title="Assign Employee Bands" for="employeeBandIds" optional />
							<MultiSelectField
								key={"employeeBandIds"}
								options={employeeBands}
								setResourceIds={setEmployeeBandIds}
								placeholder={"Select Bands"}
								pathname={PATHS.COMPANY_SETTINGS + "?view=bands"}
								required={false}
							/>
						</div>
					</>)}
					<div className="w-full">
						<Label title="Frequency" for="frequency" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.frequency
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("frequency", {
								required: "Select payment frequency here",
							})}
						>
							<option value="">
								Select Frequency
							</option>
							{FREQUENCY.map((item, index) => (
								<option 
									key={index} 
									value={item} 
								>
									{enumToStringConverter(item)}
								</option>
							))}
						</select>
						{errors.frequency && (
							<p className="text-red-500 text-sm mt-1">
								{errors.frequency.message}
							</p>
						)}
					</div>
				</div>

				<div className="mt-6">
					<h3 className="font-semibold text-base mb-3">
						Employee Contribution
					</h3>

					<div className="grid grid-cols-2 gap-x-4 gap-y-6">
						<div className="w-full">
							<Label
								title="Apply Contribution On"
								for="applyEmployeeContributionOn"
							/>
							<select
								defaultValue={isEditForm ? formDataValues?.applyEmployeeContributionOn : ""}
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
									errors.applyEmployeeContributionOn
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("applyEmployeeContributionOn", {
									required: "Select employee terms here",
								})}
								onChange={(e) => handleFixedComponent(e.target.value, true)}
							>
								<option value="">
									Select Terms
								</option>
								<option value="BASIC">Basic</option>
								<option value="GROSS">Gross</option>
								<option value="FIXED">Fixed</option>
							</select>
							{errors.applyEmployeeContributionOn && (
								<p className="text-red-500 text-sm mt-1">
									{errors.applyEmployeeContributionOn.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Employee Contribution Figure" for="employeeContributionFigure" />
							{!employeeFixedComponent ? (
								<div className="flex items-center h-14 mt-2">
									<input
										defaultValue={
											isEditForm ? 
											formDataValues?.employeeFixedComponent > 0 
												? formDataValues?.employeeFixedComponent 
												: formDataValues?.employeeRateComponent 
											: undefined
										}
										placeholder={"0"}
										type="number"
										{...register("employeeContributionFigure", {
											required: "Enter employee contribution figure",
										})}
										id="employeeContributionFigure"
										className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
											errors.employeeContributionFigure
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
									/>
									<div className="h-full w-24 flex items-center justify-center text-sm rounded-r-lg text-white bg-black px-2">
										%
									</div>
								</div>
							):( 
								<MoneyInput 
									attributes={{
										id: "employeeContributionFigure",
										placeholder: "0"
									}}
									register={register}
									name="employeeContributionFigure"
									setValue={setValue}
									defaultValue={
										isEditForm ? 
										formDataValues?.employeeFixedComponent > 0 
											? formDataValues?.employeeFixedComponent 
											: formDataValues?.employeeRateComponent 
										: undefined
									}
									required="Enter employee contribution figure"
									error={errors.employeeContributionFigure}
								/>
							)}
							{errors.employeeContributionFigure && (
								<p className="text-red-500 text-sm mt-1">
									{errors.employeeContributionFigure.message}
								</p>
							)}
						</div>
					</div>
				</div>

				<div className="mt-6">
					<h3 className="font-semibold text-base mb-3">
						Employer Contribution
					</h3>

					<div className="grid grid-cols-2 gap-x-4 gap-y-6">
						<div className="w-full">
							<Label
								title="Apply Contribution On"
								for="applyEmployerContributionOn"
							/>
							<select
								defaultValue={isEditForm ? formDataValues?.applyEmployerContributionOn : ""}
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
									errors.applyEmployerContributionOn
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
								{...register("applyEmployerContributionOn", {
									required: "Select employer terms here",
								})}
								onChange={(e) => handleFixedComponent(e.target.value, false)}
							>
								<option value="">
									Select Terms
								</option>
								<option value="BASIC">Basic</option>
								<option value="GROSS">Gross</option>
								<option value="FIXED">Fixed</option>
							</select>
							{errors.applyEmployerContributionOn && (
								<p className="text-red-500 text-sm mt-1">
									{errors.applyEmployerContributionOn.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Employer Contribution Figure" for="employerContributionFigure" />
							{!employerFixedComponent ? (
								<div className="flex items-center h-14 mt-2">
									<input
										defaultValue={
											isEditForm ? 
											formDataValues?.employerFixedComponent > 0 
												? formDataValues?.employerFixedComponent 
												: formDataValues?.employerRateComponent 
											: undefined
										}
										placeholder={"0"}
										type="number"
										{...register("employerContributionFigure", {
											required: "Enter employer contribution figure",
											pattern: {
												value: /^[0-9]*$/,
												message: "Enter only numbers",
											},
										})}
										id="employerContributionFigure"
										className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
											errors.employerContributionFigure
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`}
									/>
									<div className="h-full w-24 flex items-center justify-center text-sm rounded-r-lg text-white bg-black px-2">
										%
									</div>
								</div>
							):(
								<MoneyInput 
									attributes={{
										id: "employerContributionFigure",
										placeholder: "0"
									}}
									register={register}
									name="employerContributionFigure"
									setValue={setValue}
									defaultValue={
										isEditForm ? 
										formDataValues?.employerFixedComponent > 0 
											? formDataValues?.employerFixedComponent 
											: formDataValues?.employerRateComponent 
										: undefined
									}
									required="Enter employer contribution figure"
									error={errors.employerContributionFigure}
								/>
							)}
							{errors.employerContributionFigure && (
								<p className="text-red-500 text-sm mt-1">
									{errors.employerContributionFigure.message}
								</p>
							)}
						</div>
					</div>
				</div>

				<div className="mt-8">
					<div className="flex space-x-12 text-base">
						<h3>Statutory</h3>
						<div className="flex space-x-3 items-center">
							<input
								defaultChecked={isEditForm && formDataValues?.statutory === true}
								type="radio"
								value={"yes"}
								{...register("statutory", {
									required: "Enter statutory here",
								})}
								id="statutory"
							/>
							<label htmlFor="statutory">Yes</label>
						</div>
						<div className="flex space-x-3 items-center">
							<input
								defaultChecked={isEditForm && formDataValues?.statutory === false}
								type="radio"
								value={"no"}
								{...register("statutory", {
									required: "Enter statutory here",
								})}
								id="no-statutory"
							/>
							<label htmlFor="no-statutory">No</label>
						</div>
					</div>
					{errors.statutory && (
						<p className="text-red-500 text-sm mt-1">
							{errors.statutory.message}
						</p>
					)}
				</div>
				<div className="mt-6">
					<div className="flex space-x-12 text-base">
						<h3>Prorate</h3>
						<div className="flex space-x-3 items-center">
							<input
								defaultChecked={isEditForm && formDataValues?.prorate === true}
								type="radio"
								value={"yes"}
								{...register("prorate", {
									required: "Enter prorate here",
								})}
								id="prorate"
							/>
							<label htmlFor="prorate">Yes</label>
						</div>
						<div className="flex space-x-3 items-center">
							<input
								defaultChecked={isEditForm && formDataValues?.prorate === false}
								type="radio"
								value={"no"}
								{...register("prorate", {
									required: "Enter prorate here",
								})}
								id="no-prorate"
							/>
							<label htmlFor="no-prorate">No</label>
						</div>
					</div>
					{errors.prorate && (
						<p className="text-red-500 text-sm mt-1">
							{errors.prorate.message}
						</p>
					)}
				</div>
				
				<div className="w-full flex justify-end mt-8">
					<OrangeButton 
						type="submit"
						title={
							uploading 
							? "Uploading..." 
							: isEditForm 
								? "Update Savings Scheme" 
								: "Add Savings Scheme"
						}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading} 
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default SavingsModalForm;
