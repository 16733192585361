import { GridColDef } from "@mui/x-data-grid";
import { enumToStringConverter, formatDateAndTime, moneyFormat } from "../../../helpers/appHelpers";
import { Avatar } from "@mui/material";
import { PayrollRunStatus } from "../../../models/payroll.dto";

export const CompanyPayrollRunColumns: GridColDef[] = [
	{
		field: "payPeriod",
		headerName: "Pay Period",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code,
	},
	{
		field: "initiatedBy",
		headerName: "Initiated By",
		headerClassName: "super-app-theme--header",
		width: 300,
		renderCell: (params) => {
			const user = params.value || undefined;
			return (
				<div className="flex items-center space-x-4">
					{user && <>
						<Avatar
							src={user.photoUrl || ""}
							alt="user"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm">
							{/* {user.firstName + " " + user.lastName} */}
							Admin
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "totalCost",
		headerName: "Total Cost",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "numberOfEmployees",
		headerName: "Number Of Employees",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => new Intl.NumberFormat().format(Number(params.value)) || "--",
	},
	{
		field: "processedEmployees",
		headerName: "Processed Employees",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => new Intl.NumberFormat().format(Number(params.value)) || "--",
	},
	{
		field: "status",
		headerName: "Status",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (<>
				{params.value ? (
					<div 
						className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
						style={{ color: getPayrollRunStatusColor(params.value) }}
					>
						<div 
							className="absolute inset-0" 
							style={{ 
								background: getPayrollRunStatusColor(params.value), 
								opacity: "15%"
							}}
						/>
						<p>{params.value === "ERROR" ? "Failed" : enumToStringConverter(params.value)}</p>
					</div>
				):(
					<p>--</p>
				)}
			</>);
		},
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "actions",
		headerName: "Actions",
		width: 150,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
];

export const getPayrollRunStatusColor = (status: PayrollRunStatus) => {
	switch(status) {
		case "COMPLETED":
			return "#4CAF50"
		case "ERROR":
			return "#F44336"
		case "PROCESSING":
			return "#FFC107"
		case "SUBMITTED": 
			return "#2196F3"
		default: 
			return "#BF21F3"
	}
};

export const CompanyPayrollRunColumnVisibilityModel: Record<string, boolean> = {
	payPeriod: true,
	initiatedBy: true,
	totalCost: true,
	numberOfEmployees: true,
	processedEmployees: false,
	status: true,
	createdAt: true,
};

export const CompanyPayslipsColumns: GridColDef[] = [
	{
		field: "employee",
		headerName: "Employee",
		headerClassName: "super-app-theme--header",
		width: 280,
		renderCell: (params: any) => {
			const employee = params.value;
			return (
				<div className="flex items-center space-x-4">
					{employee && <>
						<Avatar
							src={employee?.photoUrl || ""}
							alt="employee"
							className="w-10 h-10 rounded-full"
						/>
						<p className="text-sm">
							{employee?.firstName + " " + employee?.lastName}
						</p>
					</>}
				</div>
			);
		},
	},
	{
		field: "grossAmount",
		headerName: "Gross Pay",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "basicPayAmount",
		headerName: "Net Pay",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "basicPayPerPayPeriod",
		headerName: "Net Pay Per Pay Period",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "employeePensionAmount",
		headerName: "Employee Pension Amount",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "employerPensionAmount",
		headerName: "Employer Pension Amount",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "employeeSavingSchemeAmount",
		headerName: "Employee Saving Scheme Amount",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "employerSavingSchemeAmount",
		headerName: "Employer Saving Scheme Amount",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "allowanceAmount",
		headerName: "Allowance Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "overtimeAmount",
		headerName: "Overtime Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "bonusAmount",
		headerName: "Bonus Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "backpayAmount",
		headerName: "Backpay Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "loanRepaymentAmount",
		headerName: "Loan Repayment Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "partialSalaryAmount",
		headerName: "Partial Salary Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "salaryAdvanceAmount",
		headerName: "Salary Advance Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "unionDeductionsAmount",
		headerName: "Union Deductions Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "taxAmount",
		headerName: "Tax Amount",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => moneyFormat(params.value) || "--",
	},
	{
		field: "actions",
		headerName: "Actions",
		width: 150,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
];

export const CompanyPayslipsColumnVisibilityModel: Record<string, boolean> = {
	employee: true,
	grossAmount: true,
	basicPayAmount: true,
	basicPayPerPayPeriod: true,
	employeePensionAmount: true,
	employerPensionAmount: true,
	employeeSavingSchemeAmount: false,
	employerSavingSchemeAmount: false,
	allowanceAmount: false,
	overtimeAmount: false,
	bonusAmount: false,
	backpayAmount: false,
	loanRepaymentAmount: false,
	partialSalaryAmount: false,
	salaryAdvanceAmount: false,
	unionDeductionsAmount: false,
	taxAmount: false,
};