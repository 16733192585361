import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createdAtmodifiedAtActions, formatDateAndTime } from "../../../../../helpers/appHelpers";

export const Columns: GridColDef[] = [
	{
		field: "title",
		headerName: "Title",
		width: 200,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "description",
		headerName: "Description",
		width: 350,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "code",
		headerName: "Code",
		width: 150,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "active",
		headerName: "Active",
		width: 140,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
	{
		field: "startDate",
		headerName: "Start Date",
		width: 250,
		headerClassName: "super-app-theme--header",
		valueGetter: (params: GridValueGetterParams) => {
			const startDate = formatDateAndTime(params);
			return startDate;
		},
	},
	{
		field: "endDate",
		headerName: "End Date",
		width: 250,
		headerClassName: "super-app-theme--header",
		valueGetter: (params: GridValueGetterParams) => {
			const endDate = formatDateAndTime(params);
			return endDate;
		},
	},
	{
		field: "discountPercent",
		headerName: "Discount Percent",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value + "%",
	},
	{
		field: "tags",
		headerName: "Tags",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div className="flex items-center space-x-2 line-clamp-1">
					{params.value.length ?
						<p>{params.value.join(", ")}</p> :
						<p>--</p>
					}
				</div>
			);
		},
	},
	{
		field: "activeDays",
		headerName: "Active Days",
		width: 150,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "allowUpdateDuringActivePeriod",
		headerName: "Allow Update During Active Period",
		width: 270,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},

	...createdAtmodifiedAtActions
];
