import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { IAddEditTableForm } from "../../../../helpers/appHelpers";
import APISelectField from "../../../../components/form_fields/APISelectField";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import { PayPeriodDto } from "../../../../models/pay-period.dto";
import { EmployeeDto } from "../../../../models/employee.dto";
import { EmployeeBandDto } from "../../../../models/employee-band.dto";
import MultiSelectField from "../../../../components/form_fields/MultiSelectField";
import { PATHS } from "../../../../routes/routes.paths";
import { AllowanceDto } from "../../../../models/allowance.dto";
import { BenefitDto } from "../../../../models/benefit.dto";
import { BonusDto } from "../../../../models/bonus.dto";
import { useCreatePayrollRun } from "../../../../services/payrol.service";
import { CreatePayrollDto } from "../../../../models/payroll.dto";

type IFormInputs = CreatePayrollDto;

type CreatePayrollModalFormProps = IAddEditTableForm & {
	payPeriods: PayPeriodDto[];
	employees: EmployeeDto[];
	employeeBands: EmployeeBandDto[];
	allowances: AllowanceDto[];
	benefits: BenefitDto[];
	bonuses: BonusDto[];
}

const CreatePayrollModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	payPeriods,
	employees,
	employeeBands,
	allowances,
	benefits,
	bonuses,
}: CreatePayrollModalFormProps) => {
	const { currentCompany } = useCompanyStore();
	const {formDataValues} = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		watch,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			payPeriodId: isEditForm ? formDataValues?.payPeriodId : "",
		}
	});

	const considerAllEmployees = watch("considerAllEmployees");
	const [applicableEmployeeIds, setEmployeeIds] = useState<number[]>([]);
	const [applicableEmployeeBandIds, setEmployeeBandIds] = useState<number[]>([]);
	const [applicableAllowanceIds, setAllowanceIds] = useState<number[]>([]);
	const [applicableBenefitIds, setBenefitIds] = useState<number[]>([]);
	const [applicableBonusIds, setBonusIds] = useState<number[]>([]);
	const { mutate: createPayrollRun } = useCreatePayrollRun();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);

		createPayrollRun(
			{
				payload: {
					...data,
					companyId: currentCompany?.id,
					applicableEmployeeIds,
					applicableEmployeeBandIds,
					applicableAllowanceIds,
					applicableBenefitIds,
					applicableBonusIds,
				},
				options: {},
			}, 
			{
				onSuccess: () => {
					addNewTableData();
					setOpen(false);
					reset();
				},
				onSettled: () => setUploading(false),
			}
		);
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent open={open} title={title} handleClose={handleClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Pay Period" for="payPeriodId" />
						<APISelectField 
							key="payPeriodId"
							attributes={{
								id: "payPeriodId",
							}}
							placeholder="Select Pay Period"
							register={register}
							name="payPeriodId"
							options={payPeriods}
							mapText="code"
							pathname={PATHS.SUB_COMPANY_SETTINGS.PAY_PERIODS}
							required="Select pay period here"
							error={errors.payPeriodId}
							valueAsNumber
						/>
						{errors.payPeriodId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.payPeriodId.message}
							</p>
						)}
					</div>
					<div className="col-span-2 flex space-x-3 items-center">
						<input 
							type="checkbox"
							{...register("considerAllEmployees", {
								required: false,
							})}
							id="considerAllEmployees"
						/>
						<label htmlFor="considerAllEmployees">Include All Employees</label>
					</div>
					{!considerAllEmployees && (<>
						<div className="w-full">
							<Label title="Applicable Employees" for="applicableEmployeeIds" />
							<MultiSelectField
								key={"applicableEmployeeIds"}
								options={employees}
								setResourceIds={setEmployeeIds}
								placeholder={"Select Employees"}
								pathname={PATHS.EMPLOYEES}
								required={false}
								mapTexts={["firstName", "lastName"]}
							/>
						</div>
						<div className="w-full">
							<Label title="Applicable Employee Bands" for="applicableEmployeeBandIds" />
							<MultiSelectField
								key={"applicableEmployeeBandIds"}
								options={employeeBands}
								setResourceIds={setEmployeeBandIds}
								placeholder={"Select Bands"}
								pathname={PATHS.COMPANY_SETTINGS + "?view=bands"}
								required={false}
							/>
						</div>
					</>)}
					<div className="col-span-2 flex space-x-3 items-center">
						<input 
							type="checkbox"
							{...register("considerAllComponents", {
								required: false,
							})}
							id="considerAllComponents"
						/>
						<label htmlFor="considerAllComponents">Apply All Components</label>
					</div>
					<div className="col-span-2 flex space-x-3 items-center">
						<input 
							type="checkbox"
							{...register("considerEmployeesWithExpiredContracts", {
								required: false,
							})}
							id="considerEmployeesWithExpiredContracts"
						/>
						<label htmlFor="considerEmployeesWithExpiredContracts">Include Employees With Expired Contracts</label>
					</div>
					<div className="w-full">
						<Label title="Applicable Allowances" for="applicableAllowanceIds" optional />
						<MultiSelectField
							key={"applicableAllowanceIds"}
							options={allowances}
							setResourceIds={setAllowanceIds}
							placeholder={"Select Options"}
							pathname={PATHS.BENEFIT_AND_ALLOWANCE + "?view=allowances"}
							required={false}
						/>
					</div>
					<div className="w-full">
						<Label title="Applicable Benefits" for="applicableBenefitIds" optional />
						<MultiSelectField
							key={"applicableBenefitIds"}
							options={benefits}
							setResourceIds={setBenefitIds}
							placeholder={"Select Options"}
							pathname={PATHS.BENEFIT_AND_ALLOWANCE + "?view=benefits"}
							required={false}
						/>
					</div>
					<div className="w-full">
						<Label title="Applicable Bonuses" for="applicableBonusIds" optional />
						<MultiSelectField
							key={"applicableBonusIds"}
							options={bonuses}
							setResourceIds={setBonusIds}
							placeholder={"Select Options"}
							pathname={PATHS.OVERTIME_AND_BONUSES + "?view=bonuses"}
							required={false}
						/>
					</div>
				</div>
				
				<div className="w-full flex justify-end mt-8">
					<OrangeButton
						type="submit"
						title={
							uploading
							? "Uploading..."
							: isEditForm
								? "Update Payroll Run"
								: "Submit Payroll Run"
						}
						className="px-8 text-sm"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default CreatePayrollModalForm;
